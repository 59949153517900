import { Layout } from "antd";
import React, { Component } from "react";

class MyFooter extends Component {
  render() {
    return (
      <Layout.Footer style={{ textAlign: "center" }}>
        Jiří Znoj 2019 - 2020
      </Layout.Footer>
    );
  }
}

export default MyFooter;
