import { default as ZnojMobile } from './Znoj-Mobile.js';
import { default as ZnojWEB } from './Znoj-WEB.js';
import { default as ZnojDesktop } from './Znoj-Desktop.js';

export const GitlabGroups = [
    {
        "auto_devops_enabled": null,
        "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
        "created_at": "2018-08-04T09:48:55.065Z",
        "default_branch": null,
        "default_branch_protection": 2,
        "default_branch_protection_defaults": {
            "allow_force_push": true,
            "allowed_to_merge": [
                {
                    "access_level": 30
                }
            ],
            "allowed_to_push": [
                {
                    "access_level": 30
                }
            ]
        },
        "description": "",
        "emails_disabled": false,
        "emails_enabled": true,
        "full_name": "Znoj-Desktop",
        "full_path": "Znoj-Desktop",
        "id": 3335002,
        "ldap_access": null,
        "ldap_cn": null,
        "lfs_enabled": true,
        "lock_math_rendering_limits_enabled": false,
        "math_rendering_limits_enabled": true,
        "mentions_disabled": null,
        "name": "Znoj-Desktop",
        "organization_id": 1,
        "parent_id": null,
        "path": "Znoj-Desktop",
        "project_creation_level": "developer",
        "request_access_enabled": false,
        "require_two_factor_authentication": false,
        "share_with_group_lock": false,
        "shared_runners_setting": "enabled",
        "subgroup_creation_level": "owner",
        "two_factor_grace_period": 48,
        "visibility": "public",
        "web_url": "https://gitlab.com/groups/Znoj-Desktop",
        "wiki_access_level": "enabled"
    },
    {
        "auto_devops_enabled": null,
        "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
        "created_at": "2018-08-04T09:48:16.263Z",
        "default_branch": null,
        "default_branch_protection": 2,
        "default_branch_protection_defaults": {
            "allow_force_push": true,
            "allowed_to_merge": [
                {
                    "access_level": 30
                }
            ],
            "allowed_to_push": [
                {
                    "access_level": 30
                }
            ]
        },
        "description": "",
        "emails_disabled": false,
        "emails_enabled": true,
        "full_name": "Znoj-Mobile",
        "full_path": "Znoj-Mobile",
        "id": 3335000,
        "ldap_access": null,
        "ldap_cn": null,
        "lfs_enabled": true,
        "lock_math_rendering_limits_enabled": false,
        "math_rendering_limits_enabled": true,
        "mentions_disabled": null,
        "name": "Znoj-Mobile",
        "organization_id": 1,
        "parent_id": null,
        "path": "Znoj-Mobile",
        "project_creation_level": "developer",
        "request_access_enabled": true,
        "require_two_factor_authentication": false,
        "share_with_group_lock": false,
        "shared_runners_setting": "enabled",
        "subgroup_creation_level": "owner",
        "two_factor_grace_period": 48,
        "visibility": "public",
        "web_url": "https://gitlab.com/groups/Znoj-Mobile",
        "wiki_access_level": "enabled"
    },
    {
        "auto_devops_enabled": null,
        "avatar_url": "https://gitlab.com/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
        "created_at": "2018-08-02T22:10:50.348Z",
        "default_branch": null,
        "default_branch_protection": 2,
        "default_branch_protection_defaults": {
            "allow_force_push": true,
            "allowed_to_merge": [
                {
                    "access_level": 30
                }
            ],
            "allowed_to_push": [
                {
                    "access_level": 30
                }
            ]
        },
        "description": "",
        "emails_disabled": false,
        "emails_enabled": true,
        "full_name": "Znoj-WEB",
        "full_path": "Znoj-WEB",
        "id": 3328580,
        "ldap_access": null,
        "ldap_cn": null,
        "lfs_enabled": true,
        "lock_math_rendering_limits_enabled": false,
        "math_rendering_limits_enabled": true,
        "mentions_disabled": null,
        "name": "Znoj-WEB",
        "organization_id": 1,
        "parent_id": null,
        "path": "Znoj-WEB",
        "project_creation_level": "developer",
        "request_access_enabled": false,
        "require_two_factor_authentication": false,
        "share_with_group_lock": false,
        "shared_runners_setting": "enabled",
        "subgroup_creation_level": "owner",
        "two_factor_grace_period": 48,
        "visibility": "public",
        "web_url": "https://gitlab.com/groups/Znoj-WEB",
        "wiki_access_level": "enabled"
    }
]

/* eslint-disable import/no-anonymous-default-export */
export default {
  GitlabGroups,
  ZnojMobile,
  ZnojWEB,
  ZnojDesktop
};