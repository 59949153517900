/* eslint-disable import/no-anonymous-default-export */
export default [
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/52632452/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/52632452/events",
            "issues": "https://gitlab.com/api/v4/projects/52632452/issues",
            "labels": "https://gitlab.com/api/v4/projects/52632452/labels",
            "members": "https://gitlab.com/api/v4/projects/52632452/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/52632452/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/52632452/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/52632452"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/52632452/android-chrome-192x192.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 20,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": ".*",
            "name_regex_keep": null,
            "next_run_at": "2023-11-30T22:07:33.685Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/svatba-dostalovi",
        "created_at": "2023-11-29T22:07:33.663Z",
        "creator_id": 2588219,
        "default_branch": "main",
        "description": "2023, TypeScript, Material-UI, React - Wedding Website",
        "description_html": "<p data-sourcepos=\"1:1-1:54\" dir=\"auto\">2023, TypeScript, Material-UI, React - Wedding Website</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/svatba-dostalovi.git",
        "id": 52632452,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-28T16:33:54.505Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Svatba Dost\u00e1lovi",
        "name_with_namespace": "Znoj-WEB / Svatba Dost\u00e1lovi",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "svatba-dostalovi",
        "path_with_namespace": "Znoj-WEB/svatba-dostalovi",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/svatba-dostalovi/-/blob/main/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Pij3suvVhVKCPLzbyNsy",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-svatba-dostalovi-52632452-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/svatba-dostalovi.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2023",
            "material-ui",
            "react",
            "typescript"
        ],
        "topics": [
            "2023",
            "material-ui",
            "react",
            "typescript"
        ],
        "updated_at": "2024-03-28T16:33:54.505Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/svatba-dostalovi",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/42993769/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/42993769/events",
            "issues": "https://gitlab.com/api/v4/projects/42993769/issues",
            "labels": "https://gitlab.com/api/v4/projects/42993769/labels",
            "members": "https://gitlab.com/api/v4/projects/42993769/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/42993769/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/42993769/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/42993769"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/42993769/android-chrome-192x192.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 20,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": ".*",
            "name_regex_keep": null,
            "next_run_at": "2023-01-30T14:59:28.880Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/clocks",
        "created_at": "2023-01-29T14:59:28.850Z",
        "creator_id": 2588219,
        "default_branch": "main",
        "description": "2023, TypeScript, Ant Design, React, firebase, GitLab CI/CD - Let's allocate same time for multiple clocks and switch between them in the same way as with the \"chess clocks\". Initial time can be set as well as number of clocks.",
        "description_html": "<p data-sourcepos=\"1:1-1:227\" dir=\"auto\">2023, TypeScript, Ant Design, React, firebase, GitLab CI/CD - Let's allocate same time for multiple clocks and switch between them in the same way as with the \"chess clocks\". Initial time can be set as well as number of clocks.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/clocks.git",
        "id": 42993769,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:22.446Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Game clocks",
        "name_with_namespace": "Znoj-WEB / Game clocks",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "enabled",
        "path": "clocks",
        "path_with_namespace": "Znoj-WEB/clocks",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/clocks/-/blob/main/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489412DhgQCzt4-xTbCRXBixp",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-clocks-42993769-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/clocks.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2023",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "react",
            "typescript"
        ],
        "topics": [
            "2023",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "react",
            "typescript"
        ],
        "updated_at": "2024-03-30T13:05:22.446Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/clocks",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/36382796/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/36382796/events",
            "issues": "https://gitlab.com/api/v4/projects/36382796/issues",
            "labels": "https://gitlab.com/api/v4/projects/36382796/labels",
            "members": "https://gitlab.com/api/v4/projects/36382796/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/36382796/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/36382796/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/36382796"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/36382796/firtree400.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 20,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": ".*",
            "name_regex_keep": null,
            "next_run_at": "2022-05-23T11:18:33.661Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/svatba-jedlickovi",
        "created_at": "2022-05-22T11:18:33.608Z",
        "creator_id": 2588219,
        "default_branch": "main",
        "description": "2022, TypeScript, Material-UI, React - Wedding Website",
        "description_html": "<p data-sourcepos=\"1:1-1:54\" dir=\"auto\">2022, TypeScript, Material-UI, React - Wedding Website</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/svatba-jedlickovi.git",
        "id": 36382796,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-28T16:26:35.253Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Svatba Jedli\u010dkovi",
        "name_with_namespace": "Znoj-WEB / Svatba Jedli\u010dkovi",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "svatba-jedlickovi",
        "path_with_namespace": "Znoj-WEB/svatba-jedlickovi",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/svatba-jedlickovi/-/blob/main/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941D1yE3tx5n3wrsVFwJir9",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-svatba-jedlickovi-36382796-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/svatba-jedlickovi.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2022",
            "material-ui",
            "react",
            "typescript"
        ],
        "topics": [
            "2022",
            "material-ui",
            "react",
            "typescript"
        ],
        "updated_at": "2024-03-28T16:26:35.253Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/svatba-jedlickovi",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/24267705/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/24267705/events",
            "issues": "https://gitlab.com/api/v4/projects/24267705/issues",
            "labels": "https://gitlab.com/api/v4/projects/24267705/labels",
            "members": "https://gitlab.com/api/v4/projects/24267705/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/24267705/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/24267705/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/24267705"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/24267705/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": ".*",
            "name_regex_keep": null,
            "next_run_at": "2021-02-09T23:02:19.302Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/svatba",
        "created_at": "2021-02-08T23:02:19.271Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2021, TypeScript, Material-UI, React - My Wedding Website",
        "description_html": "<p data-sourcepos=\"1:1-1:57\" dir=\"auto\">2021, TypeScript, Material-UI, React - My Wedding Website</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/svatba.git",
        "id": 24267705,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2022-01-30T15:48:52.566Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Svatba",
        "name_with_namespace": "Znoj-WEB / Svatba",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "svatba",
        "path_with_namespace": "Znoj-WEB/svatba",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/svatba/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941sK4xuTh-FnzMt63yvvfA",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-svatba-24267705-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/svatba.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2021",
            "material-ui",
            "react",
            "typescript"
        ],
        "topics": [
            "2021",
            "material-ui",
            "react",
            "typescript"
        ],
        "updated_at": "2024-01-11T05:30:30.375Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/svatba",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/23716957/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/23716957/events",
            "issues": "https://gitlab.com/api/v4/projects/23716957/issues",
            "labels": "https://gitlab.com/api/v4/projects/23716957/labels",
            "members": "https://gitlab.com/api/v4/projects/23716957/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/23716957/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/23716957/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/23716957"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/23716957/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": ".*",
            "name_regex_keep": null,
            "next_run_at": "2021-01-17T23:57:32.813Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/single-container-packing",
        "created_at": "2021-01-16T23:57:32.791Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2020, TypeScript, React, Hooks, React Bootstrap, Redux - Web app use 3dbinpacking.com to calculate if products fit the box or not.",
        "description_html": "<p data-sourcepos=\"1:1-1:130\" dir=\"auto\">2020, TypeScript, React, Hooks, React Bootstrap, Redux - Web app use 3dbinpacking.com to calculate if products fit the box or not.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/single-container-packing.git",
        "id": 23716957,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2021-01-17T23:21:41.667Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Single Container Packing",
        "name_with_namespace": "Znoj-WEB / Single Container Packing",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "enabled",
        "path": "single-container-packing",
        "path_with_namespace": "Znoj-WEB/single-container-packing",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/single-container-packing/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941TppYHEcTZub8Fq5CH_xt",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-single-container-packing-23716957-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/single-container-packing.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2020",
            "React Bootstrap",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "topics": [
            "2020",
            "React Bootstrap",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "updated_at": "2024-01-19T07:25:10.396Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/single-container-packing",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/22237060/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/22237060/events",
            "labels": "https://gitlab.com/api/v4/projects/22237060/labels",
            "members": "https://gitlab.com/api/v4/projects/22237060/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/22237060/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/22237060/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/22237060"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/22237060/icon2.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": ".*",
            "name_regex_keep": null,
            "next_run_at": "2020-11-05T17:58:20.736Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/eventio",
        "created_at": "2020-11-04T17:58:20.720Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2020, TypeScript, React, Hooks, Redux, CSS, Firebase, GitLab CI/CD - Web app that allows registered users to sign up for and create events. The app contains several pages: Sign in, Event List, Create Event, 404 Page.",
        "description_html": "<p data-sourcepos=\"1:1-1:216\" dir=\"auto\">2020, TypeScript, React, Hooks, Redux, CSS, Firebase, GitLab CI/CD - Web app that allows registered users to sign up for and create events. The app contains several pages: Sign in, Event List, Create Event, 404 Page.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/eventio.git",
        "id": 22237060,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "disabled",
        "issues_enabled": false,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-11-24T21:37:23.212Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Eventio",
        "name_with_namespace": "Znoj-WEB / Eventio",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "packages_enabled": true,
        "pages_access_level": "enabled",
        "path": "eventio",
        "path_with_namespace": "Znoj-WEB/eventio",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/eventio/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Vcua8rpfykXQuJe-75w8",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/eventio.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2020",
            "GitLab CI/CD",
            "css",
            "firebase",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "topics": [
            "2020",
            "GitLab CI/CD",
            "css",
            "firebase",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "updated_at": "2024-01-19T06:50:18.581Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/eventio",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/18439741/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/18439741/events",
            "labels": "https://gitlab.com/api/v4/projects/18439741/labels",
            "members": "https://gitlab.com/api/v4/projects/18439741/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/18439741/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/18439741/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/18439741"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/18439741/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "7d",
            "enabled": false,
            "keep_n": null,
            "name_regex": null,
            "name_regex_keep": null,
            "next_run_at": "2020-10-20T13:57:34.096Z",
            "older_than": null
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/znoj.cz-2020",
        "created_at": "2020-04-27T15:02:47.606Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2020, TypeScript, Ant Design, React, Hooks, Redux, Firebase, GitLab CI/CD - Jiri Znoj - personal website",
        "description_html": "<p data-sourcepos=\"1:1-1:104\" dir=\"auto\">2020, TypeScript, Ant Design, React, Hooks, Redux, Firebase, GitLab CI/CD - Jiri Znoj - personal website</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/znoj.cz-2020.git",
        "id": 18439741,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "disabled",
        "issues_enabled": false,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-10-09T08:50:05.028Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Znoj.cz 2020",
        "name_with_namespace": "Znoj-WEB / Znoj.cz 2020",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "znoj.cz-2020",
        "path_with_namespace": "Znoj-WEB/znoj.cz-2020",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/znoj.cz-2020/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": false,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Pv91xFSw8tzdQ2sd8JTR",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/znoj.cz-2020.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2020",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "topics": [
            "2020",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "updated_at": "2023-12-07T23:54:25.267Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/znoj.cz-2020",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/17785138/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/17785138/events",
            "issues": "https://gitlab.com/api/v4/projects/17785138/issues",
            "labels": "https://gitlab.com/api/v4/projects/17785138/labels",
            "members": "https://gitlab.com/api/v4/projects/17785138/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/17785138/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/17785138/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/17785138"
        },
        "allow_merge_on_skipped_pipeline": null,
        "allow_pipeline_trigger_approve_deployment": false,
        "analytics_access_level": "enabled",
        "approvals_before_merge": 0,
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/17785138/workout.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_restrict_pipeline_cancellation_role": "developer",
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "7d",
            "enabled": false,
            "keep_n": null,
            "name_regex": null,
            "name_regex_keep": null,
            "next_run_at": "2020-04-05T11:32:36.704Z",
            "older_than": null
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/workout",
        "created_at": "2020-03-29T11:32:36.691Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2020, TypeScript, Ant Design, React, Hooks, Redux, Firebase, GitLab CI/CD - Workout app for tracking daily exercise (now supported - PullUps, PushUps, Squats, SitUps, Burpees, HipThrust, Dips, Dumbbells, Walk, Run, Bike, Swim, RollerSkates, Plank, Gym, Fitness, Yoga)",
        "description_html": "<p data-sourcepos=\"1:1-1:267\" dir=\"auto\">2020, TypeScript, Ant Design, React, Hooks, Redux, Firebase, GitLab CI/CD - Workout app for tracking daily exercise (now supported - PullUps, PushUps, Squats, SitUps, Burpees, HipThrust, Dips, Dumbbells, Walk, Run, Bike, Swim, RollerSkates, Plank, Gym, Fitness, Yoga)</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/workout.git",
        "id": 17785138,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "issues_template": null,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2023-06-15T19:42:09.709Z",
        "lfs_enabled": true,
        "marked_for_deletion_at": null,
        "marked_for_deletion_on": null,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_pipelines_enabled": false,
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "merge_requests_template": null,
        "merge_trains_enabled": false,
        "merge_trains_skip_train_allowed": false,
        "mirror": false,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Workout",
        "name_with_namespace": "Znoj-WEB / Workout",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_all_status_checks_passed": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "workout",
        "path_with_namespace": "Znoj-WEB/workout",
        "prevent_merge_without_jira_issue": false,
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/workout/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": true,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Tgpx6tCzrD6bSsdszCFy",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-workout-17785138-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/workout.git",
        "star_count": 1,
        "suggestion_commit_message": null,
        "tag_list": [
            "2020",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "topics": [
            "2020",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "updated_at": "2023-12-09T23:34:28.744Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/workout",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/17013380/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/17013380/events",
            "issues": "https://gitlab.com/api/v4/projects/17013380/issues",
            "labels": "https://gitlab.com/api/v4/projects/17013380/labels",
            "members": "https://gitlab.com/api/v4/projects/17013380/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/17013380/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/17013380/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/17013380"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/17013380/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "7d",
            "enabled": false,
            "keep_n": null,
            "name_regex": null,
            "name_regex_keep": null,
            "next_run_at": "2020-02-26T16:41:02.073Z",
            "older_than": null
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/btc-check-2",
        "created_at": "2020-02-19T16:41:02.058Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2020, TypeScript, Ant Design, React, Hooks, Redux, Firebase, GitLab CI/CD - Balance from CoinMate count in CZK",
        "description_html": "<p data-sourcepos=\"1:1-1:110\" dir=\"auto\">2020, TypeScript, Ant Design, React, Hooks, Redux, Firebase, GitLab CI/CD - Balance from CoinMate count in CZK</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/btc-check-2.git",
        "id": 17013380,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2021-04-07T17:21:23.113Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Btc check 2",
        "name_with_namespace": "Znoj-WEB / Btc check 2",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "btc-check-2",
        "path_with_namespace": "Znoj-WEB/btc-check-2",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/btc-check-2/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941jnQMyVzeyn7iRKUh9-39",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-btc-check-2-17013380-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/btc-check-2.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2020",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "topics": [
            "2020",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "hooks",
            "react",
            "redux",
            "typescript"
        ],
        "updated_at": "2023-12-09T13:16:31.836Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/btc-check-2",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/15055778/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/15055778/events",
            "issues": "https://gitlab.com/api/v4/projects/15055778/issues",
            "labels": "https://gitlab.com/api/v4/projects/15055778/labels",
            "members": "https://gitlab.com/api/v4/projects/15055778/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/15055778/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/15055778/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/15055778"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/15055778/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/fe",
        "created_at": "2019-10-28T16:59:57.597Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, JavaScript, Material-UI, React, Firebase, GitLab CI/CD - This project is template for working with Firebase Realtime db, login and use React UI framework Material-UI",
        "description_html": "<p data-sourcepos=\"1:1-1:171\" dir=\"auto\">2019, JavaScript, Material-UI, React, Firebase, GitLab CI/CD - This project is template for working with Firebase Realtime db, login and use React UI framework Material-UI</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/fe.git",
        "id": 15055778,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:45.802Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Firebase Example",
        "name_with_namespace": "Znoj-WEB / Firebase Example",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "fe",
        "path_with_namespace": "Znoj-WEB/fe",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/fe/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941z4FaauzwXqyddtXg3FJZ",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-fe-15055778-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/fe.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2019",
            "GitLab CI/CD",
            "firebase",
            "javascript",
            "material-ui",
            "react"
        ],
        "topics": [
            "2019",
            "GitLab CI/CD",
            "firebase",
            "javascript",
            "material-ui",
            "react"
        ],
        "updated_at": "2024-03-30T13:05:45.802Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/fe",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/14222602/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/14222602/events",
            "issues": "https://gitlab.com/api/v4/projects/14222602/issues",
            "labels": "https://gitlab.com/api/v4/projects/14222602/labels",
            "members": "https://gitlab.com/api/v4/projects/14222602/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/14222602/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/14222602/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/14222602"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/14222602/tooth.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/zubar",
        "created_at": "2019-09-09T18:49:24.707Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, JavaScript, Ant Design, React, Firebase, GitLab CI/CD - Web page for my Dentist",
        "description_html": "<p data-sourcepos=\"1:1-1:85\" dir=\"auto\">2019, JavaScript, Ant Design, React, Firebase, GitLab CI/CD - Web page for my Dentist</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/zubar.git",
        "id": 14222602,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:44.870Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Zubar",
        "name_with_namespace": "Znoj-WEB / Zubar",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "zubar",
        "path_with_namespace": "Znoj-WEB/zubar",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/zubar/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941zD6zJL4zAaqjY6R1KRhF",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-zubar-14222602-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/zubar.git",
        "star_count": 1,
        "suggestion_commit_message": null,
        "tag_list": [
            "2019",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "javascript",
            "react"
        ],
        "topics": [
            "2019",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "javascript",
            "react"
        ],
        "updated_at": "2024-03-30T13:04:44.870Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/zubar",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/12295097/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/12295097/events",
            "issues": "https://gitlab.com/api/v4/projects/12295097/issues",
            "labels": "https://gitlab.com/api/v4/projects/12295097/labels",
            "members": "https://gitlab.com/api/v4/projects/12295097/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/12295097/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/12295097/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/12295097"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/12295097/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/btc-check",
        "created_at": "2019-05-12T21:14:24.224Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, JavaScript, PHP, Ant Design, React, Firebase, GitLab CI/CD - Balance from CoinMate count in CZK",
        "description_html": "<p data-sourcepos=\"1:1-1:101\" dir=\"auto\">2019, JavaScript, PHP, Ant Design, React, Firebase, GitLab CI/CD - Balance from CoinMate count in CZK</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/btc-check.git",
        "id": 12295097,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2021-03-09T14:42:18.532Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "BTC check",
        "name_with_namespace": "Znoj-WEB / BTC check",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "btc-check",
        "path_with_namespace": "Znoj-WEB/btc-check",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/btc-check/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941nByyhJGCLyVNJG_tsMN2",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-btc-check-12295097-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/btc-check.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2019",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "javascript",
            "php",
            "react"
        ],
        "topics": [
            "2019",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "javascript",
            "php",
            "react"
        ],
        "updated_at": "2024-01-11T04:25:53.630Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/btc-check",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/10354964/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/10354964/events",
            "issues": "https://gitlab.com/api/v4/projects/10354964/issues",
            "labels": "https://gitlab.com/api/v4/projects/10354964/labels",
            "members": "https://gitlab.com/api/v4/projects/10354964/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10354964/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10354964/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10354964"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10354964/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/gitlabprojects",
        "created_at": "2019-01-14T10:59:55.303Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, JavaScript,  Ant Design, React, Firebase, GitLab CI/CD - Web page for presenting public parts of my personal GitLab.  \r\nData are prepared by my other GitLab project - https://gitlab.com/Znoj-Desktop/pythongitlabscript",
        "description_html": "<p data-sourcepos=\"1:1-2:97\" dir=\"auto\">2019, JavaScript,  Ant Design, React, Firebase, GitLab CI/CD - Web page for presenting public parts of my personal GitLab. \nData are prepared by my other GitLab project - <a href=\"https://gitlab.com/Znoj-Desktop/pythongitlabscript\">https://gitlab.com/Znoj-Desktop/pythongitlabscript</a></p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/gitlabprojects.git",
        "id": 10354964,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:08.807Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Gitlab Projects",
        "name_with_namespace": "Znoj-WEB / Gitlab Projects",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "gitlabprojects",
        "path_with_namespace": "Znoj-WEB/gitlabprojects",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/gitlabprojects/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941jn7sRhuxszT9gHMSV4jZ",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-gitlabprojects-10354964-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/gitlabprojects.git",
        "star_count": 1,
        "suggestion_commit_message": null,
        "tag_list": [
            "2019",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "javascript",
            "react"
        ],
        "topics": [
            "2019",
            "Ant Design",
            "GitLab CI/CD",
            "firebase",
            "javascript",
            "react"
        ],
        "updated_at": "2024-03-30T13:05:08.807Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/gitlabprojects",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7761697/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7761697/events",
            "issues": "https://gitlab.com/api/v4/projects/7761697/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761697/labels",
            "members": "https://gitlab.com/api/v4/projects/7761697/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761697/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761697/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761697"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761697/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/knihovna_is_wa",
        "created_at": "2018-08-04T23:01:09.993Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, ASP.NET - School project (WebApps) - ASP.NET app - Informative system of the library.  \r\nTask and conclusion in Czech language:  ",
        "description_html": "<p data-sourcepos=\"1:1-2:40\" dir=\"auto\">2015, ASP.NET - School project (WebApps) - ASP.NET app - Informative system of the library. \nTask and conclusion in Czech language:</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/knihovna_IS_WA.git",
        "id": 7761697,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-11-06T20:34:19.828Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "IS Knihovna",
        "name_with_namespace": "Znoj-WEB / IS Knihovna",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "knihovna_IS_WA",
        "path_with_namespace": "Znoj-WEB/knihovna_IS_WA",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/knihovna_IS_WA/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941DEAHaquJWSemE8JQ6ag4",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-knihovna-is-wa-7761697-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/knihovna_IS_WA.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "asp.net"
        ],
        "topics": [
            "2015",
            "asp.net"
        ],
        "updated_at": "2024-01-19T05:09:55.403Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/knihovna_IS_WA",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7759489/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7759489/events",
            "issues": "https://gitlab.com/api/v4/projects/7759489/issues",
            "labels": "https://gitlab.com/api/v4/projects/7759489/labels",
            "members": "https://gitlab.com/api/v4/projects/7759489/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7759489/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7759489/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7759489"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7759489/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/kolstejn",
        "created_at": "2018-08-04T17:14:55.248Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, HTML, WordPress - Official web page of action Kol\u0161tejnsk\u00fd okruh created with Dominik Ther",
        "description_html": "<p data-sourcepos=\"1:1-1:97\" dir=\"auto\">2013, HTML, WordPress - Official web page of action Kol\u0161tejnsk\u00fd okruh created with Dominik Ther</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/kolstejn.git",
        "id": 7759489,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:33.284Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Kol\u0161tejn",
        "name_with_namespace": "Znoj-WEB / Kol\u0161tejn",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "kolstejn",
        "path_with_namespace": "Znoj-WEB/kolstejn",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/kolstejn/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941xAk6_sGZzgZbGs1gpvHZ",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-kolstejn-7759489-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/kolstejn.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2013",
            "html",
            "wordpress"
        ],
        "topics": [
            "2013",
            "html",
            "wordpress"
        ],
        "updated_at": "2024-03-30T13:05:33.284Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/kolstejn",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738885/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738885/events",
            "issues": "https://gitlab.com/api/v4/projects/7738885/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738885/labels",
            "members": "https://gitlab.com/api/v4/projects/7738885/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738885/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738885/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738885"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738885/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/znojcz",
        "created_at": "2018-08-02T21:39:17.287Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2016, HTML, PHP, Materialize - My personal web page mainly for school purposes",
        "description_html": "<p data-sourcepos=\"1:1-1:78\" dir=\"auto\">2016, HTML, PHP, Materialize - My personal web page mainly for school purposes</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/znojCz.git",
        "id": 7738885,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T20:42:37.836Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "znoj.cz",
        "name_with_namespace": "Znoj-WEB / znoj.cz",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "znojCz",
        "path_with_namespace": "Znoj-WEB/znojCz",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/znojCz/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941JoL96oZ2aKFdmZHEV3UE",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-znojcz-7738885-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/znojCz.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2016",
            "Materialize",
            "html",
            "php"
        ],
        "topics": [
            "2016",
            "Materialize",
            "html",
            "php"
        ],
        "updated_at": "2024-01-19T04:58:58.730Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/znojCz",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738869/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738869/events",
            "issues": "https://gitlab.com/api/v4/projects/7738869/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738869/labels",
            "members": "https://gitlab.com/api/v4/projects/7738869/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738869/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738869/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738869"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738869/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/sim",
        "created_at": "2018-08-02T21:37:22.351Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, HTML, PHP, Material Design Lite, GitLab CI/CD - Bookmarks for my favourite shows",
        "description_html": "<p data-sourcepos=\"1:1-1:86\" dir=\"auto\">2017, HTML, PHP, Material Design Lite, GitLab CI/CD - Bookmarks for my favourite shows</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/sim.git",
        "id": 7738869,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T20:42:16.471Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Z\u00e1lo\u017eky pro seri\u00e1ly",
        "name_with_namespace": "Znoj-WEB / Z\u00e1lo\u017eky pro seri\u00e1ly",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "sim",
        "path_with_namespace": "Znoj-WEB/sim",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/sim/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941a7r6-TDCZCuXsaEYsd-8",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-sim-7738869-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/sim.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "GitLab CI/CD",
            "Material Design Lite",
            "html",
            "php"
        ],
        "topics": [
            "2017",
            "GitLab CI/CD",
            "Material Design Lite",
            "html",
            "php"
        ],
        "updated_at": "2024-01-12T04:30:51.554Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/sim",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738851/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738851/events",
            "issues": "https://gitlab.com/api/v4/projects/7738851/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738851/labels",
            "members": "https://gitlab.com/api/v4/projects/7738851/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738851/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738851/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738851"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738851/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/pvbps",
        "created_at": "2018-08-02T21:34:07.764Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, HTML, PHP, Materialize - Web for school subject Computer viruses and security of computer systems",
        "description_html": "<p data-sourcepos=\"1:1-1:103\" dir=\"auto\">2017, HTML, PHP, Materialize - Web for school subject Computer viruses and security of computer systems</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/pvbps.git",
        "id": 7738851,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:03.421Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Po\u010d\u00edta\u010dov\u00e9 viry a bezpe\u010dnost po\u010d\u00edta\u010dov\u00fdch syst\u00e9m\u016f",
        "name_with_namespace": "Znoj-WEB / Po\u010d\u00edta\u010dov\u00e9 viry a bezpe\u010dnost po\u010d\u00edta\u010dov\u00fdch syst\u00e9m\u016f",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "pvbps",
        "path_with_namespace": "Znoj-WEB/pvbps",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/pvbps/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941exigyxyvLzyJ77KVehCf",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-pvbps-7738851-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/pvbps.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "Materialize",
            "html",
            "php"
        ],
        "topics": [
            "2017",
            "Materialize",
            "html",
            "php"
        ],
        "updated_at": "2024-03-30T13:05:03.421Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/pvbps",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738840/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738840/events",
            "issues": "https://gitlab.com/api/v4/projects/7738840/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738840/labels",
            "members": "https://gitlab.com/api/v4/projects/7738840/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738840/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738840/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738840"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738840/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/profiq",
        "created_at": "2018-08-02T21:32:42.823Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2016, HTML, PHP, Materialize - Dashboard for different versions of tested framework. Should be used on tablet and phones where framework will be tested",
        "description_html": "<p data-sourcepos=\"1:1-1:151\" dir=\"auto\">2016, HTML, PHP, Materialize - Dashboard for different versions of tested framework. Should be used on tablet and phones where framework will be tested</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/profiq.git",
        "id": 7738840,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:49.522Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "profiq dashboard concept",
        "name_with_namespace": "Znoj-WEB / profiq dashboard concept",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "profiq",
        "path_with_namespace": "Znoj-WEB/profiq",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/profiq/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489419Hfxx4_SDnk_SGnL5HUa",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-profiq-7738840-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/profiq.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2016",
            "Materialize",
            "html",
            "php",
            "profiq"
        ],
        "topics": [
            "2016",
            "Materialize",
            "html",
            "php",
            "profiq"
        ],
        "updated_at": "2024-03-30T13:04:49.522Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/profiq",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738827/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738827/events",
            "issues": "https://gitlab.com/api/v4/projects/7738827/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738827/labels",
            "members": "https://gitlab.com/api/v4/projects/7738827/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738827/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738827/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738827"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738827/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/pr1",
        "created_at": "2018-08-02T21:31:05.214Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2016, HTML, PHP, Materialize - Web for school subject Programming I",
        "description_html": "<p data-sourcepos=\"1:1-1:67\" dir=\"auto\">2016, HTML, PHP, Materialize - Web for school subject Programming I</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/pr1.git",
        "id": 7738827,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:12.911Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Programov\u00e1n\u00ed 1",
        "name_with_namespace": "Znoj-WEB / Programov\u00e1n\u00ed 1",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "pr1",
        "path_with_namespace": "Znoj-WEB/pr1",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/pr1/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941B-iH3u2kfomRpu6XgcRG",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-pr1-7738827-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/pr1.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2016",
            "Materialize",
            "html",
            "php"
        ],
        "topics": [
            "2016",
            "Materialize",
            "html",
            "php"
        ],
        "updated_at": "2024-03-30T13:05:12.911Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/pr1",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738815/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738815/events",
            "issues": "https://gitlab.com/api/v4/projects/7738815/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738815/labels",
            "members": "https://gitlab.com/api/v4/projects/7738815/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738815/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738815/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738815"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738815/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/pou",
        "created_at": "2018-08-02T21:30:43.660Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, HTML, PHP, Materialize - Web for school subject Computer defence and attack",
        "description_html": "<p data-sourcepos=\"1:1-1:81\" dir=\"auto\">2017, HTML, PHP, Materialize - Web for school subject Computer defence and attack</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/pou.git",
        "id": 7738815,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T16:16:10.520Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Po\u010d\u00edta\u010dov\u00e1 obrana a \u00fatok",
        "name_with_namespace": "Znoj-WEB / Po\u010d\u00edta\u010dov\u00e1 obrana a \u00fatok",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "pou",
        "path_with_namespace": "Znoj-WEB/pou",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/pou/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941HwoemuuMoW7NSQMHPDsj",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-pou-7738815-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/pou.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "Materialize",
            "html",
            "php"
        ],
        "topics": [
            "2017",
            "Materialize",
            "html",
            "php"
        ],
        "updated_at": "2024-01-19T04:58:42.796Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/pou",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738796/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738796/events",
            "issues": "https://gitlab.com/api/v4/projects/7738796/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738796/labels",
            "members": "https://gitlab.com/api/v4/projects/7738796/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738796/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738796/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738796"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738796/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/fei8ucz",
        "created_at": "2018-08-02T21:28:20.275Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, CSS, PHP- Files and pages created during my school days for me or for other students created with Dominik Ther",
        "description_html": "<p data-sourcepos=\"1:1-1:116\" dir=\"auto\">2012, CSS, PHP- Files and pages created during my school days for me or for other students created with Dominik Ther</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/fei8ucz.git",
        "id": 7738796,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:29.430Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "fei.8u.cz",
        "name_with_namespace": "Znoj-WEB / fei.8u.cz",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "fei8ucz",
        "path_with_namespace": "Znoj-WEB/fei8ucz",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/fei8ucz/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Sn3sR7bVuiEqrvPPxnyK",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-fei8ucz-7738796-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/fei8ucz.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2012",
            "css",
            "php"
        ],
        "topics": [
            "2012",
            "css",
            "php"
        ],
        "updated_at": "2024-03-30T13:05:29.430Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/fei8ucz",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738782/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738782/events",
            "issues": "https://gitlab.com/api/v4/projects/7738782/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738782/labels",
            "members": "https://gitlab.com/api/v4/projects/7738782/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738782/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738782/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738782"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738782/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/pulrocnivyroci",
        "created_at": "2018-08-02T21:26:59.442Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2016, HTML, Material Design Lite - Page for anniversary with hidden content - part of cipher I made",
        "description_html": "<p data-sourcepos=\"1:1-1:99\" dir=\"auto\">2016, HTML, Material Design Lite - Page for anniversary with hidden content - part of cipher I made</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/pulrocniVyroci.git",
        "id": 7738782,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-11-06T20:35:12.299Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "V\u00fdro\u010d\u00ed",
        "name_with_namespace": "Znoj-WEB / V\u00fdro\u010d\u00ed",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "pulrocniVyroci",
        "path_with_namespace": "Znoj-WEB/pulrocniVyroci",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/pulrocniVyroci/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": false,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489417wMFv5ymFHnHx1gMNYxa",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-pulrocnivyroci-7738782-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/pulrocniVyroci.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2016",
            "Material Design Lite",
            "html"
        ],
        "topics": [
            "2016",
            "Material Design Lite",
            "html"
        ],
        "updated_at": "2024-01-12T04:30:51.451Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/pulrocniVyroci",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738679/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738679/events",
            "issues": "https://gitlab.com/api/v4/projects/7738679/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738679/labels",
            "members": "https://gitlab.com/api/v4/projects/7738679/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738679/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738679/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738679"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738679/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/ymca",
        "created_at": "2018-08-02T21:21:00.251Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, ExtJS - Simple download page created with ExtJS framework",
        "description_html": "<p data-sourcepos=\"1:1-1:63\" dir=\"auto\">2017, ExtJS - Simple download page created with ExtJS framework</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/ymca.git",
        "id": 7738679,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-11-06T20:35:32.688Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Download page YMCA",
        "name_with_namespace": "Znoj-WEB / Download page YMCA",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "ymca",
        "path_with_namespace": "Znoj-WEB/ymca",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/ymca/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941DnPNzc8wonWuJZtnf8Xa",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-ymca-7738679-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/ymca.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "extjs"
        ],
        "topics": [
            "2017",
            "extjs"
        ],
        "updated_at": "2024-01-19T04:40:23.578Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/ymca",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738669/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738669/events",
            "issues": "https://gitlab.com/api/v4/projects/7738669/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738669/labels",
            "members": "https://gitlab.com/api/v4/projects/7738669/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738669/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738669/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738669"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738669/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/zed",
        "created_at": "2018-08-02T21:19:19.798Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, CSS, HTML, MySQL, PHP - Wall for notes",
        "description_html": "<p data-sourcepos=\"1:1-1:44\" dir=\"auto\">2012, CSS, HTML, MySQL, PHP - Wall for notes</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/zed.git",
        "id": 7738669,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:57.225Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Ze\u010f",
        "name_with_namespace": "Znoj-WEB / Ze\u010f",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "zed",
        "path_with_namespace": "Znoj-WEB/zed",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/zed/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941yvoz_iPJjvek78H6sPSZ",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-zed-7738669-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/zed.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2012",
            "css",
            "html",
            "mysql",
            "php"
        ],
        "topics": [
            "2012",
            "css",
            "html",
            "mysql",
            "php"
        ],
        "updated_at": "2024-03-30T13:04:57.225Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/zed",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738650/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738650/events",
            "issues": "https://gitlab.com/api/v4/projects/7738650/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738650/labels",
            "members": "https://gitlab.com/api/v4/projects/7738650/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738650/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738650/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738650"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738650/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/leasiocz",
        "created_at": "2018-08-02T21:15:33.881Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, CSS, HTML - Web created from photoshop designer",
        "description_html": "<p data-sourcepos=\"1:1-1:53\" dir=\"auto\">2015, CSS, HTML - Web created from photoshop designer</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/leasioCz.git",
        "id": 7738650,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:41.852Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Leasio",
        "name_with_namespace": "Znoj-WEB / Leasio",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "leasioCz",
        "path_with_namespace": "Znoj-WEB/leasioCz",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/leasioCz/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941mzMXXqsMz9qKyxiA5teT",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-leasiocz-7738650-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/leasioCz.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "css",
            "html"
        ],
        "topics": [
            "2015",
            "css",
            "html"
        ],
        "updated_at": "2024-03-30T13:05:41.852Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/leasioCz",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738593/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738593/events",
            "issues": "https://gitlab.com/api/v4/projects/7738593/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738593/labels",
            "members": "https://gitlab.com/api/v4/projects/7738593/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738593/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738593/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738593"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738593/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/kolousekfilmy",
        "created_at": "2018-08-02T21:09:44.363Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, CSS, HTML - My movies library with links to trailers",
        "description_html": "<p data-sourcepos=\"1:1-1:58\" dir=\"auto\">2015, CSS, HTML - My movies library with links to trailers</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/KolousekFilmy.git",
        "id": 7738593,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T20:41:30.254Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Kolou\u0161ek Filmy",
        "name_with_namespace": "Znoj-WEB / Kolou\u0161ek Filmy",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "KolousekFilmy",
        "path_with_namespace": "Znoj-WEB/KolousekFilmy",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/KolousekFilmy/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941PqwuxJKkTrTxm56jnJwq",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-kolousekfilmy-7738593-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/KolousekFilmy.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "css",
            "html"
        ],
        "topics": [
            "2015",
            "css",
            "html"
        ],
        "updated_at": "2024-01-19T04:49:51.269Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/KolousekFilmy",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738539/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738539/events",
            "issues": "https://gitlab.com/api/v4/projects/7738539/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738539/labels",
            "members": "https://gitlab.com/api/v4/projects/7738539/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738539/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738539/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738539"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738539/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/junakbludov",
        "created_at": "2018-08-02T21:03:12.081Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, Drupal - Current scout's web page - main informative medium",
        "description_html": "<p data-sourcepos=\"1:1-1:65\" dir=\"auto\">2013, Drupal - Current scout's web page - main informative medium</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/junakBludov.git",
        "id": 7738539,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-11-06T20:34:13.810Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Skautsk\u00e9 str\u00e1nky 2013",
        "name_with_namespace": "Znoj-WEB / Skautsk\u00e9 str\u00e1nky 2013",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "junakBludov",
        "path_with_namespace": "Znoj-WEB/junakBludov",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/junakBludov/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941eLGGPt2_ozkpFrmjWKqx",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-junakbludov-7738539-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/junakBludov.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2013",
            "drupal"
        ],
        "topics": [
            "2013",
            "drupal"
        ],
        "updated_at": "2024-01-12T04:30:43.617Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/junakBludov",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738436/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738436/events",
            "issues": "https://gitlab.com/api/v4/projects/7738436/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738436/labels",
            "members": "https://gitlab.com/api/v4/projects/7738436/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738436/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738436/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738436"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738436/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/jakomensky",
        "created_at": "2018-08-02T20:58:44.536Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, HTML - Web page for my friend's school project - outdoor game about Jan \u00c1mos Komensk\u00fd",
        "description_html": "<p data-sourcepos=\"1:1-1:93\" dir=\"auto\">2014, HTML - Web page for my friend's school project - outdoor game about Jan \u00c1mos Komensk\u00fd</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/JAKomensky.git",
        "id": 7738436,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T20:41:09.367Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "J. A. Komensk\u00fd",
        "name_with_namespace": "Znoj-WEB / J. A. Komensk\u00fd",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "JAKomensky",
        "path_with_namespace": "Znoj-WEB/JAKomensky",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/JAKomensky/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941zsuHw7S9g7iPd_M74PVa",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-jakomensky-7738436-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/JAKomensky.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2014",
            "html"
        ],
        "topics": [
            "2014",
            "html"
        ],
        "updated_at": "2024-01-19T04:58:21.915Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/JAKomensky",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738146/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738146/events",
            "issues": "https://gitlab.com/api/v4/projects/7738146/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738146/labels",
            "members": "https://gitlab.com/api/v4/projects/7738146/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738146/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738146/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738146"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738146/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/kolstejnracingteam",
        "created_at": "2018-08-02T20:51:31.314Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, HTML - Official web page of 2 veteran riders",
        "description_html": "<p data-sourcepos=\"1:1-1:50\" dir=\"auto\">2011, HTML - Official web page of 2 veteran riders</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/kolstejnRacingTeam.git",
        "id": 7738146,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T20:41:41.099Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Kol\u0161tejn Racing Team",
        "name_with_namespace": "Znoj-WEB / Kol\u0161tejn Racing Team",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "kolstejnRacingTeam",
        "path_with_namespace": "Znoj-WEB/kolstejnRacingTeam",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/kolstejnRacingTeam/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941dmhjsevBZEg1kBYjha6W",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-kolstejnracingteam-7738146-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/kolstejnRacingTeam.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2011",
            "html"
        ],
        "topics": [
            "2011",
            "html"
        ],
        "updated_at": "2024-01-19T04:57:52.184Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/kolstejnRacingTeam",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738131/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738131/events",
            "issues": "https://gitlab.com/api/v4/projects/7738131/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738131/labels",
            "members": "https://gitlab.com/api/v4/projects/7738131/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738131/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738131/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738131"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738131/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/zamecnictvistastny",
        "created_at": "2018-08-02T20:49:44.620Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2010, HTML - Web page for locksmithery",
        "description_html": "<p data-sourcepos=\"1:1-1:38\" dir=\"auto\">2010, HTML - Web page for locksmithery</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/zamecnictviStastny.git",
        "id": 7738131,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T17:44:08.007Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Z\u00e1me\u010dnictv\u00ed \u0160tastn\u00fd",
        "name_with_namespace": "Znoj-WEB / Z\u00e1me\u010dnictv\u00ed \u0160tastn\u00fd",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "zamecnictviStastny",
        "path_with_namespace": "Znoj-WEB/zamecnictviStastny",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/zamecnictviStastny/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Xc-RvYMux9NW33ELNFry",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-zamecnictvistastny-7738131-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/zamecnictviStastny.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2010",
            "html"
        ],
        "topics": [
            "2010",
            "html"
        ],
        "updated_at": "2024-01-19T04:39:44.952Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/zamecnictviStastny",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738097/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738097/events",
            "issues": "https://gitlab.com/api/v4/projects/7738097/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738097/labels",
            "members": "https://gitlab.com/api/v4/projects/7738097/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738097/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738097/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738097"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738097/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/konebludov",
        "created_at": "2018-08-02T20:46:28.674Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2010, HTML - Web page for horse riders",
        "description_html": "<p data-sourcepos=\"1:1-1:38\" dir=\"auto\">2010, HTML - Web page for horse riders</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/koneBludov.git",
        "id": 7738097,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T20:41:46.205Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Kon\u011b Bludov",
        "name_with_namespace": "Znoj-WEB / Kon\u011b Bludov",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "koneBludov",
        "path_with_namespace": "Znoj-WEB/koneBludov",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/koneBludov/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Z9ymUUru6Q5yYFsitHn5",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-konebludov-7738097-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/koneBludov.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2010",
            "html"
        ],
        "topics": [
            "2010",
            "html"
        ],
        "updated_at": "2024-01-19T04:39:45.349Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/koneBludov",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738081/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738081/events",
            "issues": "https://gitlab.com/api/v4/projects/7738081/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738081/labels",
            "members": "https://gitlab.com/api/v4/projects/7738081/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738081/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738081/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738081"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738081/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/junak2",
        "created_at": "2018-08-02T20:43:50.102Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2009, HTML - Scout's web page used for 4 years",
        "description_html": "<p data-sourcepos=\"1:1-1:46\" dir=\"auto\">2009, HTML - Scout's web page used for 4 years</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/junak2.git",
        "id": 7738081,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:37.607Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Skautsk\u00e9 str\u00e1nky 2009",
        "name_with_namespace": "Znoj-WEB / Skautsk\u00e9 str\u00e1nky 2009",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "junak2",
        "path_with_namespace": "Znoj-WEB/junak2",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/junak2/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941bsW6qyvSVF2x9oaCGvvX",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-junak2-7738081-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/junak2.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2009",
            "html"
        ],
        "topics": [
            "2009",
            "html"
        ],
        "updated_at": "2024-03-30T13:05:37.607Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/junak2",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7738047/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7738047/events",
            "issues": "https://gitlab.com/api/v4/projects/7738047/issues",
            "labels": "https://gitlab.com/api/v4/projects/7738047/labels",
            "members": "https://gitlab.com/api/v4/projects/7738047/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7738047/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7738047/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7738047"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7738047/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/algotazky",
        "created_at": "2018-08-02T20:39:19.341Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, HTML - Fulfilled questions for ALG II",
        "description_html": "<p data-sourcepos=\"1:1-1:43\" dir=\"auto\">2012, HTML - Fulfilled questions for ALG II</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/algOtazky.git",
        "id": 7738047,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-02-11T21:54:41.536Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Algoritmy II - ot\u00e1zky",
        "name_with_namespace": "Znoj-WEB / Algoritmy II - ot\u00e1zky",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "algOtazky",
        "path_with_namespace": "Znoj-WEB/algOtazky",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/algOtazky/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941DdsVJ442rweiTH7GMN4o",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-algotazky-7738047-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/algOtazky.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2012",
            "html"
        ],
        "topics": [
            "2012",
            "html"
        ],
        "updated_at": "2024-01-19T04:57:51.441Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/algOtazky",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7737642/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7737642/events",
            "issues": "https://gitlab.com/api/v4/projects/7737642/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737642/labels",
            "members": "https://gitlab.com/api/v4/projects/7737642/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737642/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737642/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737642"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737642/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/junak1",
        "created_at": "2018-08-02T19:57:23.992Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2005, HTML - My first try for scout's web page",
        "description_html": "<p data-sourcepos=\"1:1-1:46\" dir=\"auto\">2005, HTML - My first try for scout's web page</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/junak1.git",
        "id": 7737642,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-02-11T21:55:35.040Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Skautsk\u00e9 str\u00e1nky 2005",
        "name_with_namespace": "Znoj-WEB / Skautsk\u00e9 str\u00e1nky 2005",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "junak1",
        "path_with_namespace": "Znoj-WEB/junak1",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/junak1/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941nci3hDrCr-ySAi3P-xk-",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-junak1-7737642-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/junak1.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2005",
            "html"
        ],
        "topics": [
            "2005",
            "html"
        ],
        "updated_at": "2024-01-19T04:39:19.969Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/junak1",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7737622/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7737622/events",
            "issues": "https://gitlab.com/api/v4/projects/7737622/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737622/labels",
            "members": "https://gitlab.com/api/v4/projects/7737622/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737622/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737622/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737622"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737622/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/zs",
        "created_at": "2018-08-02T19:55:08.221Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2005, HTML - My first personal HTML page",
        "description_html": "<p data-sourcepos=\"1:1-1:40\" dir=\"auto\">2005, HTML - My first personal HTML page</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/zs.git",
        "id": 7737622,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T17:34:47.153Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Z\u0160",
        "name_with_namespace": "Znoj-WEB / Z\u0160",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "zs",
        "path_with_namespace": "Znoj-WEB/zs",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/zs/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941ftF3tWaw_JuWsD1YW7Ha",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-zs-7737622-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/zs.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2005",
            "html"
        ],
        "topics": [
            "2005",
            "html"
        ],
        "updated_at": "2024-01-19T04:39:19.910Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/zs",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7737602/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7737602/events",
            "issues": "https://gitlab.com/api/v4/projects/7737602/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737602/labels",
            "members": "https://gitlab.com/api/v4/projects/7737602/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737602/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737602/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737602"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737602/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/andulahtml2005",
        "created_at": "2018-08-02T19:52:16.931Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2005, HTML - Simple HTML page for my sister created in elementary school",
        "description_html": "<p data-sourcepos=\"1:1-1:72\" dir=\"auto\">2005, HTML - Simple HTML page for my sister created in elementary school</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/andulaHTML2005.git",
        "id": 7737602,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:53.380Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Andula HTML",
        "name_with_namespace": "Znoj-WEB / Andula HTML",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "andulaHTML2005",
        "path_with_namespace": "Znoj-WEB/andulaHTML2005",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/andulaHTML2005/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": false,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941WEmgz6UyKscsiBtLCtSh",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-andulahtml2005-7737602-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/andulaHTML2005.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2005",
            "html"
        ],
        "topics": [
            "2005",
            "html"
        ],
        "updated_at": "2024-03-30T13:04:53.380Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/andulaHTML2005",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7737563/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7737563/events",
            "issues": "https://gitlab.com/api/v4/projects/7737563/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737563/labels",
            "members": "https://gitlab.com/api/v4/projects/7737563/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737563/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737563/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737563"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737563/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/farnostpostrelmov",
        "created_at": "2018-08-02T19:50:03.628Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, CSS, HTML - Layout of web page for Post\u0159elmov's parish",
        "description_html": "<p data-sourcepos=\"1:1-1:61\" dir=\"auto\">2014, CSS, HTML - Layout of web page for Post\u0159elmov's parish</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/farnostPostrelmov.git",
        "id": 7737563,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-02-11T21:55:09.600Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Farnost Post\u0159elmov",
        "name_with_namespace": "Znoj-WEB / Farnost Post\u0159elmov",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "farnostPostrelmov",
        "path_with_namespace": "Znoj-WEB/farnostPostrelmov",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/farnostPostrelmov/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941sE7LBbo9wyxDXPkxaqWw",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-farnostpostrelmov-7737563-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/farnostPostrelmov.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2014",
            "css",
            "html"
        ],
        "topics": [
            "2014",
            "css",
            "html"
        ],
        "updated_at": "2024-01-19T04:57:25.339Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/farnostPostrelmov",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7737537/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7737537/events",
            "issues": "https://gitlab.com/api/v4/projects/7737537/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737537/labels",
            "members": "https://gitlab.com/api/v4/projects/7737537/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737537/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737537/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737537"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737537/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/android_hra",
        "created_at": "2018-08-02T19:47:06.235Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, HTML - Page for my android game SOKOBAN",
        "description_html": "<p data-sourcepos=\"1:1-1:45\" dir=\"auto\">2013, HTML - Page for my android game SOKOBAN</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/android_hra.git",
        "id": 7737537,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T20:40:30.865Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Android - Sokoban",
        "name_with_namespace": "Znoj-WEB / Android - Sokoban",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "android_hra",
        "path_with_namespace": "Znoj-WEB/android_hra",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/android_hra/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489415FhU-S2gCq5yVhxwzWsx",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-android-hra-7737537-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/android_hra.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2013",
            "html"
        ],
        "topics": [
            "2013",
            "html"
        ],
        "updated_at": "2024-01-12T04:29:30.999Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/android_hra",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7737515/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7737515/events",
            "issues": "https://gitlab.com/api/v4/projects/7737515/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737515/labels",
            "members": "https://gitlab.com/api/v4/projects/7737515/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737515/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737515/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737515"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737515/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/alda-knihy.info",
        "created_at": "2018-08-02T19:44:10.265Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, WordPress - Simple WordPress e-shop.",
        "description_html": "<p data-sourcepos=\"1:1-1:42\" dir=\"auto\">2015, WordPress - Simple WordPress e-shop.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/alda-knihy.info.git",
        "id": 7737515,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T20:40:23.731Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "alda-knihy.info",
        "name_with_namespace": "Znoj-WEB / alda-knihy.info",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "alda-knihy.info",
        "path_with_namespace": "Znoj-WEB/alda-knihy.info",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/alda-knihy.info/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941uiNGsRAdnRZ3Nxv4Cmdq",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-alda-knihy-info-7737515-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/alda-knihy.info.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "wordpress"
        ],
        "topics": [
            "2015",
            "wordpress"
        ],
        "updated_at": "2024-01-19T04:57:25.272Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/alda-knihy.info",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7737171/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7737171/events",
            "issues": "https://gitlab.com/api/v4/projects/7737171/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737171/labels",
            "members": "https://gitlab.com/api/v4/projects/7737171/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737171/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737171/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737171"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737171/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/255",
        "created_at": "2018-08-02T19:06:18.846Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, CSS, HTML, PHP, Materialize - Web page for creating or viewing list of wishes - for Birthdays, Christmases, Weddings... Started with Dominik Ther, design 2015",
        "description_html": "<p data-sourcepos=\"1:1-1:164\" dir=\"auto\">2012, CSS, HTML, PHP, Materialize - Web page for creating or viewing list of wishes - for Birthdays, Christmases, Weddings... Started with Dominik Ther, design 2015</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/255.git",
        "id": 7737171,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2020-04-29T20:40:16.916Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "255 - Seznam p\u0159\u00e1n\u00ed",
        "name_with_namespace": "Znoj-WEB / 255 - Seznam p\u0159\u00e1n\u00ed",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "255",
        "path_with_namespace": "Znoj-WEB/255",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/255/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941sEymyrxnE-yKDR8spp7z",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-255-7737171-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/255.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2012",
            "Materialize",
            "css",
            "html",
            "php"
        ],
        "topics": [
            "2012",
            "Materialize",
            "css",
            "html",
            "php"
        ],
        "updated_at": "2024-01-12T04:29:19.961Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/255",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7543238/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7543238/events",
            "issues": "https://gitlab.com/api/v4/projects/7543238/issues",
            "labels": "https://gitlab.com/api/v4/projects/7543238/labels",
            "members": "https://gitlab.com/api/v4/projects/7543238/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7543238/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7543238/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7543238"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7543238/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-web/casreact",
        "created_at": "2018-07-18T21:10:01.000Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2018, JavaScript - React web app for countdown until chosen date and time in several units",
        "description_html": "<p data-sourcepos=\"1:1-1:90\" dir=\"auto\">2018, JavaScript - React web app for countdown until chosen date and time in several units</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-WEB/casReact.git",
        "id": 7543238,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:05:17.964Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "\u010cas",
        "name_with_namespace": "Znoj-WEB / \u010cas",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3328580/Screenshot_1.png",
            "full_path": "Znoj-WEB",
            "id": 3328580,
            "kind": "group",
            "name": "Znoj-WEB",
            "parent_id": null,
            "path": "Znoj-WEB",
            "web_url": "https://gitlab.com/groups/Znoj-WEB"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "casReact",
        "path_with_namespace": "Znoj-WEB/casReact",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-WEB/casReact/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489416mxxQb7CzU9rh45JzUqz",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-web-casreact-7543238-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-WEB/casReact.git",
        "star_count": 1,
        "suggestion_commit_message": null,
        "tag_list": [
            "2018",
            "GitLab CI/CD",
            "javascript",
            "material-ui",
            "react"
        ],
        "topics": [
            "2018",
            "GitLab CI/CD",
            "javascript",
            "material-ui",
            "react"
        ],
        "updated_at": "2024-03-30T13:05:17.964Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-WEB/casReact",
        "wiki_access_level": "private",
        "wiki_enabled": true
    }
]