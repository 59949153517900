import { Divider, Layout, Menu } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./AppMenu.css";
import { GitlabGroups } from "./configs/groups";

class AppMenu extends Component {
  render() {
    const group = this.props.group;
    const project = this.props.project;
    const groupIndex = this.props.groupIndex;

    return (
      <Layout.Sider
        className="sider"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
      >
        <Menu
          className="menu"
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[groupIndex]}
        >
          {Object.keys(GitlabGroups).map((key) => (
            <Menu.Item key={key}>
              <Link to={"/" + GitlabGroups[key].full_path}>
                {GitlabGroups[key].full_name}{" "}
              </Link>
            </Menu.Item>
          ))}
          <Menu.Item key={Object.keys(GitlabGroups).length.toString()}>
            <Link to={"/all"}>All groups</Link>
          </Menu.Item>

          <Menu.Item disabled key={"div1"}>
            <Divider />
          </Menu.Item>
          {project ? (
            <Menu.Item key={group}>
              <Link to={"/" + group}>Back to Group {group}</Link>
            </Menu.Item>
          ) : null}
          {group ? (
            <Menu.Item key={"home"}>
              <Link to={"/"}>Back to Home</Link>
            </Menu.Item>
          ) : null}
          <Menu.Item key={"znoj"}>
            <a href="https://znoj.cz">Znoj.cz</a>
          </Menu.Item>
          <Menu.Item disabled key={"div2"}>
            <Divider />
          </Menu.Item>
        </Menu>
      </Layout.Sider>
    );
  }
}

export default AppMenu;
