/* eslint-disable import/no-anonymous-default-export */
export default [
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/34775641/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/34775641/events",
            "issues": "https://gitlab.com/api/v4/projects/34775641/issues",
            "labels": "https://gitlab.com/api/v4/projects/34775641/labels",
            "members": "https://gitlab.com/api/v4/projects/34775641/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/34775641/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/34775641/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/34775641"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/34775641/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 20,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": ".*",
            "name_regex_keep": null,
            "next_run_at": "2022-03-25T15:03:06.594Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/advent-of-code-2021",
        "created_at": "2022-03-24T15:03:06.552Z",
        "creator_id": 2588219,
        "default_branch": "main",
        "description": "2021, JavaScript - Advent calendar of small programming puzzles",
        "description_html": "<p data-sourcepos=\"1:1-1:63\" dir=\"auto\">2021, JavaScript - Advent calendar of small programming puzzles</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/advent-of-code-2021.git",
        "id": 34775641,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:25.379Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Advent of Code 2021",
        "name_with_namespace": "Znoj-Desktop / Advent of Code 2021",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "enabled",
        "path": "advent-of-code-2021",
        "path_with_namespace": "Znoj-Desktop/advent-of-code-2021",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/advent-of-code-2021/-/blob/main/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941TGvzZNaHS-Lax7EAyFxf",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-advent-of-code-2021-34775641-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/advent-of-code-2021.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2021",
            "javascript"
        ],
        "topics": [
            "2021",
            "javascript"
        ],
        "updated_at": "2024-03-30T13:04:25.379Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/advent-of-code-2021",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/24869994/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/24869994/events",
            "issues": "https://gitlab.com/api/v4/projects/24869994/issues",
            "labels": "https://gitlab.com/api/v4/projects/24869994/labels",
            "members": "https://gitlab.com/api/v4/projects/24869994/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/24869994/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/24869994/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/24869994"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/24869994/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": ".*",
            "name_regex_keep": null,
            "next_run_at": "2021-03-05T13:55:14.828Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/website-checker-app",
        "created_at": "2021-03-04T13:55:14.782Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2021, TypeScript, Electron, React, Fluent UI, WebDriver - App for checking item existence in e-shop. ",
        "description_html": "<p data-sourcepos=\"1:1-1:101\" dir=\"auto\">2021, TypeScript, Electron, React, Fluent UI, WebDriver - App for checking item existence in e-shop.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/website-checker-app.git",
        "id": 24869994,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:10.817Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Website Checker App",
        "name_with_namespace": "Znoj-Desktop / Website Checker App",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "website-checker-app",
        "path_with_namespace": "Znoj-Desktop/website-checker-app",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/website-checker-app/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941mS_7VMqMSGrWNnNvpZJ3",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-website-checker-app-24869994-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/website-checker-app.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2021",
            "electron",
            "fluent ui",
            "react",
            "typescript",
            "webdriver"
        ],
        "topics": [
            "2021",
            "electron",
            "fluent ui",
            "react",
            "typescript",
            "webdriver"
        ],
        "updated_at": "2024-03-30T13:01:10.817Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/website-checker-app",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/24589316/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/24589316/events",
            "issues": "https://gitlab.com/api/v4/projects/24589316/issues",
            "labels": "https://gitlab.com/api/v4/projects/24589316/labels",
            "members": "https://gitlab.com/api/v4/projects/24589316/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/24589316/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/24589316/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/24589316"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/24589316/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": ".*",
            "name_regex_keep": null,
            "next_run_at": "2021-02-23T13:59:53.379Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/website-checker-console",
        "created_at": "2021-02-22T13:59:53.345Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2021, TypeScript, NodeJS, WebDriver - Scripts for checking item existence in an e-shop. ",
        "description_html": "<p data-sourcepos=\"1:1-1:88\" dir=\"auto\">2021, TypeScript, NodeJS, WebDriver - Scripts for checking item existence in an e-shop.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/website-checker-console.git",
        "id": 24589316,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T12:58:49.398Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Website Checker Console",
        "name_with_namespace": "Znoj-Desktop / Website Checker Console",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "website-checker-console",
        "path_with_namespace": "Znoj-Desktop/website-checker-console",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/website-checker-console/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR134894156DPU9gkuGP2cKtdzcDJ",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-website-checker-console-24589316-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/website-checker-console.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2021",
            "nodejs",
            "typescript",
            "webdriver"
        ],
        "topics": [
            "2021",
            "nodejs",
            "typescript",
            "webdriver"
        ],
        "updated_at": "2024-03-30T12:58:49.398Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/website-checker-console",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/22026142/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/22026142/events",
            "issues": "https://gitlab.com/api/v4/projects/22026142/issues",
            "labels": "https://gitlab.com/api/v4/projects/22026142/labels",
            "members": "https://gitlab.com/api/v4/projects/22026142/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/22026142/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/22026142/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/22026142"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/22026142/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": "",
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "1d",
            "enabled": false,
            "keep_n": 10,
            "name_regex": null,
            "name_regex_keep": null,
            "next_run_at": "2020-10-27T11:39:59.247Z",
            "older_than": "90d"
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/template-node.js",
        "created_at": "2020-10-26T11:39:59.235Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2020, JavaScript, Node.js - Template for process console/file/files in NodeJS",
        "description_html": "<p data-sourcepos=\"1:1-1:77\" dir=\"auto\">2020, JavaScript, Node.js - Template for process console/file/files in NodeJS</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/template-node.js.git",
        "id": 22026142,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:21.205Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Template Node.js",
        "name_with_namespace": "Znoj-Desktop / Template Node.js",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "enabled",
        "path": "template-node.js",
        "path_with_namespace": "Znoj-Desktop/template-node.js",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/template-node.js/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941hwnGqnmK1hCi3YGuDXA8",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-template-node-js-22026142-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/template-node.js.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2020",
            "javascript",
            "node.js"
        ],
        "topics": [
            "2020",
            "javascript",
            "node.js"
        ],
        "updated_at": "2024-03-30T13:04:21.205Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/template-node.js",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/18101912/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/18101912/events",
            "issues": "https://gitlab.com/api/v4/projects/18101912/issues",
            "labels": "https://gitlab.com/api/v4/projects/18101912/labels",
            "members": "https://gitlab.com/api/v4/projects/18101912/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/18101912/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/18101912/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/18101912"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/18101912/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": true,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "7d",
            "enabled": false,
            "keep_n": null,
            "name_regex": null,
            "name_regex_keep": null,
            "next_run_at": "2020-10-20T08:52:05.623Z",
            "older_than": null
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/django-small-projects",
        "created_at": "2020-04-13T12:12:30.336Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, Python - Django small projects",
        "description_html": "<p data-sourcepos=\"1:1-1:36\" dir=\"auto\">2012, Python - Django small projects</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/django-small-projects.git",
        "id": 18101912,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T12:59:02.768Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Django - small projects",
        "name_with_namespace": "Znoj-Desktop / Django - small projects",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "django-small-projects",
        "path_with_namespace": "Znoj-Desktop/django-small-projects",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/django-small-projects/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941ocmyaozXWzFUzqw8dx9H",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-django-small-projects-18101912-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/django-small-projects.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2012",
            "python"
        ],
        "topics": [
            "2012",
            "python"
        ],
        "updated_at": "2024-03-30T12:59:02.768Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/django-small-projects",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/16683146/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/16683146/events",
            "issues": "https://gitlab.com/api/v4/projects/16683146/issues",
            "labels": "https://gitlab.com/api/v4/projects/16683146/labels",
            "members": "https://gitlab.com/api/v4/projects/16683146/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/16683146/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/16683146/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/16683146"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/16683146/icon.jpg",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "7d",
            "enabled": false,
            "keep_n": null,
            "name_regex": null,
            "name_regex_keep": null,
            "next_run_at": "2020-02-10T22:22:18.842Z",
            "older_than": null
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/google-code-jam",
        "created_at": "2020-02-03T22:22:18.787Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2020, JavaScript - Google\u2019s longest running global coding competition",
        "description_html": "<p data-sourcepos=\"1:1-1:71\" dir=\"auto\">2020, JavaScript - Google\u2019s longest running global coding competition</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/google-code-jam.git",
        "id": 16683146,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:39.852Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Google Code Jam",
        "name_with_namespace": "Znoj-Desktop / Google Code Jam",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "disabled",
        "path": "google-code-jam",
        "path_with_namespace": "Znoj-Desktop/google-code-jam",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/google-code-jam/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941rsgpcYqfY11tXeh38_e2",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-google-code-jam-16683146-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "disabled",
        "snippets_enabled": false,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/google-code-jam.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2020",
            "javascript"
        ],
        "topics": [
            "2020",
            "javascript"
        ],
        "updated_at": "2024-03-30T13:04:39.852Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/google-code-jam",
        "wiki_access_level": "disabled",
        "wiki_enabled": false
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/16259394/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/16259394/events",
            "issues": "https://gitlab.com/api/v4/projects/16259394/issues",
            "labels": "https://gitlab.com/api/v4/projects/16259394/labels",
            "members": "https://gitlab.com/api/v4/projects/16259394/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/16259394/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/16259394/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/16259394"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/16259394/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": 50,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_expiration_policy": {
            "cadence": "7d",
            "enabled": false,
            "keep_n": null,
            "name_regex": null,
            "name_regex_keep": null,
            "next_run_at": null,
            "older_than": null
        },
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/messengerphotorename",
        "created_at": "2020-01-10T21:20:10.262Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2020, JavaScript, Node.js - rename photos from backup of facebook messenger",
        "description_html": "<p data-sourcepos=\"1:1-1:75\" dir=\"auto\">2020, JavaScript, Node.js - rename photos from backup of facebook messenger</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/messengerphotorename.git",
        "id": 16259394,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T12:58:54.923Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "messengerPhotoRename",
        "name_with_namespace": "Znoj-Desktop / messengerPhotoRename",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "disabled",
        "path": "messengerphotorename",
        "path_with_namespace": "Znoj-Desktop/messengerphotorename",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/messengerphotorename/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": true,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941LSzSro5WRWemiYxayjaE",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-messengerphotorename-16259394-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "disabled",
        "snippets_enabled": false,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/messengerphotorename.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2020",
            "javascript",
            "node.js"
        ],
        "topics": [
            "2020",
            "javascript",
            "node.js"
        ],
        "updated_at": "2024-03-30T12:58:54.923Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/messengerphotorename",
        "wiki_access_level": "disabled",
        "wiki_enabled": false
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/10578374/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/10578374/events",
            "issues": "https://gitlab.com/api/v4/projects/10578374/issues",
            "labels": "https://gitlab.com/api/v4/projects/10578374/labels",
            "members": "https://gitlab.com/api/v4/projects/10578374/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10578374/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10578374/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10578374"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10578374/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/pythongitlabscript",
        "created_at": "2019-01-28T18:07:22.046Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, Python - Scripts for download, update and modified all my gitlab groups and projects and scripts for creating public content from gitlab projects to be presented in my personal web site.",
        "description_html": "<p data-sourcepos=\"1:1-1:192\" dir=\"auto\">2019, Python - Scripts for download, update and modified all my gitlab groups and projects and scripts for creating public content from gitlab projects to be presented in my personal web site.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/pythongitlabscript.git",
        "id": 10578374,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:01.550Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "PythonGitLabScript",
        "name_with_namespace": "Znoj-Desktop / PythonGitLabScript",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "pythongitlabscript",
        "path_with_namespace": "Znoj-Desktop/pythongitlabscript",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/pythongitlabscript/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941YKFmxDozvdCy_6mSBxNy",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-pythongitlabscript-10578374-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/pythongitlabscript.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2019",
            "python"
        ],
        "topics": [
            "2019",
            "python"
        ],
        "updated_at": "2024-03-30T13:04:01.550Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/pythongitlabscript",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/10343473/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/10343473/events",
            "issues": "https://gitlab.com/api/v4/projects/10343473/issues",
            "labels": "https://gitlab.com/api/v4/projects/10343473/labels",
            "members": "https://gitlab.com/api/v4/projects/10343473/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10343473/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10343473/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10343473"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10343473/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/python-gui",
        "created_at": "2019-01-13T15:29:40.384Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, Python - Python mini programs for setting GUI in python",
        "description_html": "<p data-sourcepos=\"1:1-1:61\" dir=\"auto\">2014, Python - Python mini programs for setting GUI in python</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/python-gui.git",
        "id": 10343473,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T12:58:58.949Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Python GUI",
        "name_with_namespace": "Znoj-Desktop / Python GUI",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "python-gui",
        "path_with_namespace": "Znoj-Desktop/python-gui",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/python-gui/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941y7vASsjtxdHPXYdyxcX9",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-python-gui-10343473-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/python-gui.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2014",
            "python"
        ],
        "topics": [
            "2014",
            "python"
        ],
        "updated_at": "2024-03-30T13:04:45.153Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/python-gui",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/10233968/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/10233968/events",
            "issues": "https://gitlab.com/api/v4/projects/10233968/issues",
            "labels": "https://gitlab.com/api/v4/projects/10233968/labels",
            "members": "https://gitlab.com/api/v4/projects/10233968/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10233968/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10233968/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10233968"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10233968/liferay-logo.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/liferay-scripts",
        "created_at": "2019-01-06T21:11:50.608Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, Python, Shell - Scripts for Liferay Portal",
        "description_html": "<p data-sourcepos=\"1:1-1:48\" dir=\"auto\">2017, Python, Shell - Scripts for Liferay Portal</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/liferay-scripts.git",
        "id": 10233968,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-28T16:20:24.319Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Liferay scripts",
        "name_with_namespace": "Znoj-Desktop / Liferay scripts",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "liferay-scripts",
        "path_with_namespace": "Znoj-Desktop/liferay-scripts",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/liferay-scripts/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941rVy_bHF1s6NswUWh8TM_",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-liferay-scripts-10233968-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/liferay-scripts.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "profiq",
            "python",
            "shell"
        ],
        "topics": [
            "2017",
            "profiq",
            "python",
            "shell"
        ],
        "updated_at": "2024-01-11T10:33:17.630Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/liferay-scripts",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/10223244/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/10223244/events",
            "issues": "https://gitlab.com/api/v4/projects/10223244/issues",
            "labels": "https://gitlab.com/api/v4/projects/10223244/labels",
            "members": "https://gitlab.com/api/v4/projects/10223244/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/10223244/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/10223244/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/10223244"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/10223244/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/coordinates",
        "created_at": "2019-01-05T21:19:00.436Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2019, C - get greatest from areas around coordinates",
        "description_html": "<p data-sourcepos=\"1:1-1:52\" dir=\"auto\">2019, C - get greatest from areas around coordinates</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/coordinates.git",
        "id": 10223244,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:23.299Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Coordinates",
        "name_with_namespace": "Znoj-Desktop / Coordinates",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "coordinates",
        "path_with_namespace": "Znoj-Desktop/coordinates",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/coordinates/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941a_4AQnuL8vy-3z8J3iTr",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-coordinates-10223244-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/coordinates.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2019",
            "C"
        ],
        "topics": [
            "2019",
            "C"
        ],
        "updated_at": "2024-03-30T13:01:23.299Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/coordinates",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7944199/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7944199/events",
            "labels": "https://gitlab.com/api/v4/projects/7944199/labels",
            "members": "https://gitlab.com/api/v4/projects/7944199/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7944199/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7944199/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7944199"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7944199/blog_error.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/senchatest",
        "created_at": "2018-08-14T21:32:28.981Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, JS - Blog post",
        "description_html": "<p data-sourcepos=\"1:1-1:20\" dir=\"auto\">2017, JS - Blog post</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/senchaTest.git",
        "id": 7944199,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "disabled",
        "issues_enabled": false,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-02-02T18:17:02.921Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Sencha Test",
        "name_with_namespace": "Znoj-Desktop / Sencha Test",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "packages_enabled": false,
        "pages_access_level": "disabled",
        "path": "senchaTest",
        "path_with_namespace": "Znoj-Desktop/senchaTest",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/senchaTest/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941pCmsjAxx73zB87iPzckP",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "disabled",
        "snippets_enabled": false,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/senchaTest.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "js",
            "profiq"
        ],
        "topics": [
            "2017",
            "js",
            "profiq"
        ],
        "updated_at": "2024-01-12T06:35:44.512Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/senchaTest",
        "wiki_access_level": "disabled",
        "wiki_enabled": false
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7944180/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7944180/events",
            "issues": "https://gitlab.com/api/v4/projects/7944180/issues",
            "labels": "https://gitlab.com/api/v4/projects/7944180/labels",
            "members": "https://gitlab.com/api/v4/projects/7944180/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7944180/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7944180/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7944180"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7944180/pasted-image-0-9.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/liferayportlet",
        "created_at": "2018-08-14T21:29:44.820Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, Java + JS - Blog posts",
        "description_html": "<p data-sourcepos=\"1:1-1:28\" dir=\"auto\">2017, Java + JS - Blog posts</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/liferayPortlet.git",
        "id": 7944180,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-02-02T18:15:42.129Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Liferay portlet",
        "name_with_namespace": "Znoj-Desktop / Liferay portlet",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "private",
        "path": "liferayPortlet",
        "path_with_namespace": "Znoj-Desktop/liferayPortlet",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/liferayPortlet/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489419mnNgv4dXXLGU_B6JMV1",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-liferayportlet-7944180-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/liferayPortlet.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "java",
            "js",
            "profiq"
        ],
        "topics": [
            "2017",
            "java",
            "js",
            "profiq"
        ],
        "updated_at": "2024-01-12T06:35:44.364Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/liferayPortlet",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7786591/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7786591/events",
            "issues": "https://gitlab.com/api/v4/projects/7786591/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786591/labels",
            "members": "https://gitlab.com/api/v4/projects/7786591/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786591/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786591/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786591"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786591/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/pr1",
        "created_at": "2018-08-06T22:37:54.800Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, C++ - Projects for class PR1 (descriptions, resolutions, inputs, outputs)",
        "description_html": "<p data-sourcepos=\"1:1-1:79\" dir=\"auto\">2017, C++ - Projects for class PR1 (descriptions, resolutions, inputs, outputs)</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/pr1.git",
        "id": 7786591,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-20T22:43:54.935Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "PR1",
        "name_with_namespace": "Znoj-Desktop / PR1",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "pr1",
        "path_with_namespace": "Znoj-Desktop/pr1",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/pr1/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941fnrcs7W8tLcakCLSQSCs",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-pr1-7786591-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/pr1.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "C++"
        ],
        "topics": [
            "2017",
            "C++"
        ],
        "updated_at": "2024-01-12T05:10:56.237Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/pr1",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7786507/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7786507/events",
            "issues": "https://gitlab.com/api/v4/projects/7786507/issues",
            "labels": "https://gitlab.com/api/v4/projects/7786507/labels",
            "members": "https://gitlab.com/api/v4/projects/7786507/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7786507/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7786507/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7786507"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7786507/asmIcon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/asmtest",
        "created_at": "2018-08-06T22:29:00.030Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, ASM - basics of ASM for a class \"Computer viruses and security of computer systems\"",
        "description_html": "<p data-sourcepos=\"1:1-1:89\" dir=\"auto\">2017, ASM - basics of ASM for a class \"Computer viruses and security of computer systems\"</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/asmTest.git",
        "id": 7786507,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:50.682Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "ASM basics",
        "name_with_namespace": "Znoj-Desktop / ASM basics",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "asmTest",
        "path_with_namespace": "Znoj-Desktop/asmTest",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/asmTest/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941CcbMRbUdkju-YDx4yX8s",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-asmtest-7786507-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/asmTest.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "asm"
        ],
        "topics": [
            "2017",
            "asm"
        ],
        "updated_at": "2024-03-30T13:01:50.682Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/asmTest",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7785945/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7785945/events",
            "issues": "https://gitlab.com/api/v4/projects/7785945/issues",
            "labels": "https://gitlab.com/api/v4/projects/7785945/labels",
            "members": "https://gitlab.com/api/v4/projects/7785945/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7785945/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7785945/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7785945"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7785945/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/spotreba",
        "created_at": "2018-08-06T21:32:41.252Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - Program pro v\u00fdpo\u010det spot\u0159eby vody, elektriky, plynu",
        "description_html": "<p data-sourcepos=\"1:1-1:66\" dir=\"auto\">2011, C++ - Program pro v\u00fdpo\u010det spot\u0159eby vody, elektriky, plynu</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/spotreba.git",
        "id": 7785945,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-20T22:44:20.305Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Spot\u0159eba",
        "name_with_namespace": "Znoj-Desktop / Spot\u0159eba",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "spotreba",
        "path_with_namespace": "Znoj-Desktop/spotreba",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/spotreba/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489412fCbHadD_1sfpeC2XbWz",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-spotreba-7785945-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/spotreba.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2011",
            "C++"
        ],
        "topics": [
            "2011",
            "C++"
        ],
        "updated_at": "2024-01-19T05:28:58.937Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/spotreba",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7785922/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7785922/events",
            "issues": "https://gitlab.com/api/v4/projects/7785922/issues",
            "labels": "https://gitlab.com/api/v4/projects/7785922/labels",
            "members": "https://gitlab.com/api/v4/projects/7785922/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7785922/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7785922/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7785922"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7785922/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/operace_s_cisly",
        "created_at": "2018-08-06T21:30:19.853Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - Program prov\u00e1d\u00ed operace s\u010d\u00edt\u00e1n\u00ed, od\u010d\u00edt\u00e1n\u00ed, n\u00e1soben\u00ed, faktori\u00e1l nad velk\u00fdmi \u010d\u00edsly",
        "description_html": "<p data-sourcepos=\"1:1-1:108\" dir=\"auto\">2011, C++ - Program prov\u00e1d\u00ed operace s\u010d\u00edt\u00e1n\u00ed, od\u010d\u00edt\u00e1n\u00ed, n\u00e1soben\u00ed, faktori\u00e1l nad velk\u00fdmi \u010d\u00edsly</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/operace_s_cisly.git",
        "id": 7785922,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:09.813Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Operace s velk\u00fdmi \u010d\u00edsly",
        "name_with_namespace": "Znoj-Desktop / Operace s velk\u00fdmi \u010d\u00edsly",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "operace_s_cisly",
        "path_with_namespace": "Znoj-Desktop/operace_s_cisly",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/operace_s_cisly/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941F1-Ar2CHA5qumfyv6Kze",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-operace-s-cisly-7785922-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/operace_s_cisly.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2011",
            "C++"
        ],
        "topics": [
            "2011",
            "C++"
        ],
        "updated_at": "2024-03-30T13:04:09.813Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/operace_s_cisly",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7769429/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7769429/events",
            "issues": "https://gitlab.com/api/v4/projects/7769429/issues",
            "labels": "https://gitlab.com/api/v4/projects/7769429/labels",
            "members": "https://gitlab.com/api/v4/projects/7769429/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7769429/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7769429/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7769429"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7769429/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/velka_cisla",
        "created_at": "2018-08-05T19:09:41.273Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - Program prov\u00e1d\u00ed operace s\u010d\u00edt\u00e1n\u00ed, od\u010d\u00edt\u00e1n\u00ed a n\u00e1soben\u00ed nad velk\u00fdmi \u010d\u00edsly",
        "description_html": "<p data-sourcepos=\"1:1-1:97\" dir=\"auto\">2011, C++ - Program prov\u00e1d\u00ed operace s\u010d\u00edt\u00e1n\u00ed, od\u010d\u00edt\u00e1n\u00ed a n\u00e1soben\u00ed nad velk\u00fdmi \u010d\u00edsly</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/velka_cisla.git",
        "id": 7769429,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:03:57.870Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Velk\u00e1 \u010d\u00edsla",
        "name_with_namespace": "Znoj-Desktop / Velk\u00e1 \u010d\u00edsla",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "velka_cisla",
        "path_with_namespace": "Znoj-Desktop/velka_cisla",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/velka_cisla/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Xu4wGPWw_TivQ4QCdcN_",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-velka-cisla-7769429-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/velka_cisla.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2011",
            "C++"
        ],
        "topics": [
            "2011",
            "C++"
        ],
        "updated_at": "2024-03-30T13:03:57.870Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/velka_cisla",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7769428/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7769428/events",
            "issues": "https://gitlab.com/api/v4/projects/7769428/issues",
            "labels": "https://gitlab.com/api/v4/projects/7769428/labels",
            "members": "https://gitlab.com/api/v4/projects/7769428/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7769428/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7769428/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7769428"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7769428/logo.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/logaritmus",
        "created_at": "2018-08-05T19:09:15.928Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - V\u00fdpo\u010det logaritmu podle podle vzorce ln(x) + ln(1 + e^(ln(y)-log(x))),",
        "description_html": "<p data-sourcepos=\"1:1-1:84\" dir=\"auto\">2011, C++ - V\u00fdpo\u010det logaritmu podle podle vzorce ln(x) + ln(1 + e^(ln(y)-log(x))),</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/logaritmus.git",
        "id": 7769428,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:02:06.917Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Logaritmus",
        "name_with_namespace": "Znoj-Desktop / Logaritmus",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "logaritmus",
        "path_with_namespace": "Znoj-Desktop/logaritmus",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/logaritmus/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489415FdckLgkBpvHp9hxAD8y",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-logaritmus-7769428-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/logaritmus.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2011",
            "C++"
        ],
        "topics": [
            "2011",
            "C++"
        ],
        "updated_at": "2024-03-30T13:02:06.917Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/logaritmus",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7761640/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7761640/events",
            "issues": "https://gitlab.com/api/v4/projects/7761640/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761640/labels",
            "members": "https://gitlab.com/api/v4/projects/7761640/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761640/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761640/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761640"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761640/maze_icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/maze",
        "created_at": "2018-08-04T22:46:56.074Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, C++ - Rekurzivn\u00ed, nerekurzivn\u00ed, paraleln\u00ed algoritmy a jejich srovn\u00e1n\u00ed",
        "description_html": "<p data-sourcepos=\"1:1-1:80\" dir=\"auto\">2014, C++ - Rekurzivn\u00ed, nerekurzivn\u00ed, paraleln\u00ed algoritmy a jejich srovn\u00e1n\u00ed</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/maze.git",
        "id": 7761640,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:02:14.988Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Maze - Hled\u00e1n\u00ed cykl\u016f v bludi\u0161ti",
        "name_with_namespace": "Znoj-Desktop / Maze - Hled\u00e1n\u00ed cykl\u016f v bludi\u0161ti",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "maze",
        "path_with_namespace": "Znoj-Desktop/maze",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/maze/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941oN8rTCMSec7LcV3pBHvh",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-maze-7761640-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/maze.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2014",
            "C++"
        ],
        "topics": [
            "2014",
            "C++"
        ],
        "updated_at": "2024-03-30T13:02:14.988Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/maze",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7761531/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7761531/events",
            "issues": "https://gitlab.com/api/v4/projects/7761531/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761531/labels",
            "members": "https://gitlab.com/api/v4/projects/7761531/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761531/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761531/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761531"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761531/catalanIcon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/catalan",
        "created_at": "2018-08-04T22:33:55.368Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, C - Recursive and non-recursive algorithm for counting Catalan number",
        "description_html": "<p data-sourcepos=\"1:1-1:75\" dir=\"auto\">2014, C - Recursive and non-recursive algorithm for counting Catalan number</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/catalan.git",
        "id": 7761531,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:15.025Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Catalan number algs",
        "name_with_namespace": "Znoj-Desktop / Catalan number algs",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "catalan",
        "path_with_namespace": "Znoj-Desktop/catalan",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/catalan/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941uRpZFTNVHNXZuCTE41qa",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-catalan-7761531-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/catalan.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2014",
            "C"
        ],
        "topics": [
            "2014",
            "C"
        ],
        "updated_at": "2024-03-30T13:01:15.025Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/catalan",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7761484/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7761484/events",
            "issues": "https://gitlab.com/api/v4/projects/7761484/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761484/labels",
            "members": "https://gitlab.com/api/v4/projects/7761484/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761484/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761484/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761484"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761484/IS_KNIHOVNA_zakaznik.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/knihovna_is",
        "created_at": "2018-08-04T22:22:42.784Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, C# - Vytvo\u0159en\u00ed IS knihovny, kde lze proch\u00e1zet a vyhled\u00e1vat knihy, kter\u00e9 knihovna nab\u00edz\u00ed, zobrazovat detaily o autorovi v\u010detn\u011b fotky, \u017eivotopisu a knih, kter\u00e9 se od n\u011bj v knihovn\u011b nach\u00e1z\u00ed...",
        "description_html": "<p data-sourcepos=\"1:1-1:210\" dir=\"auto\">2013, C# - Vytvo\u0159en\u00ed IS knihovny, kde lze proch\u00e1zet a vyhled\u00e1vat knihy, kter\u00e9 knihovna nab\u00edz\u00ed, zobrazovat detaily o autorovi v\u010detn\u011b fotky, \u017eivotopisu a knih, kter\u00e9 se od n\u011bj v knihovn\u011b nach\u00e1z\u00ed...</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/knihovna_IS.git",
        "id": 7761484,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:02:20.675Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "IS knihovna",
        "name_with_namespace": "Znoj-Desktop / IS knihovna",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "knihovna_IS",
        "path_with_namespace": "Znoj-Desktop/knihovna_IS",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/knihovna_IS/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941YNSqA3EC8Tbu_PQqZhwb",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-knihovna-is-7761484-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/knihovna_IS.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2013",
            "C#"
        ],
        "topics": [
            "2013",
            "C#"
        ],
        "updated_at": "2024-03-30T13:02:20.675Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/knihovna_IS",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7760403/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7760403/events",
            "issues": "https://gitlab.com/api/v4/projects/7760403/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760403/labels",
            "members": "https://gitlab.com/api/v4/projects/7760403/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760403/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760403/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760403"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760403/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/pos",
        "created_at": "2018-08-04T19:28:23.374Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, Network - Network design",
        "description_html": "<p data-sourcepos=\"1:1-1:30\" dir=\"auto\">2013, Network - Network design</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/pos.git",
        "id": 7760403,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:02:29.991Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "pos",
        "name_with_namespace": "Znoj-Desktop / pos",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "pos",
        "path_with_namespace": "Znoj-Desktop/pos",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/pos/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941zo5k8M9RxnsuX_Bsy4qs",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-pos-7760403-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/pos.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2013",
            "network"
        ],
        "topics": [
            "2013",
            "network"
        ],
        "updated_at": "2024-03-30T13:02:29.991Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/pos",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7760309/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7760309/events",
            "issues": "https://gitlab.com/api/v4/projects/7760309/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760309/labels",
            "members": "https://gitlab.com/api/v4/projects/7760309/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760309/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760309/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760309"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760309/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/mhd_is",
        "created_at": "2018-08-04T19:10:43.273Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, C#, ASP.NET - Implementace \u010d\u00e1sti IS navr\u017een\u00e9ho v [MHD - n\u00e1vrh IS a SQL skripty](https://gitlab.com/Znoj-Desktop/MHD)",
        "description_html": "<p data-sourcepos=\"1:1-1:127\" dir=\"auto\">2013, C#, ASP.NET - Implementace \u010d\u00e1sti IS navr\u017een\u00e9ho v <a data-sourcepos=\"1:60-1:127\" href=\"https://gitlab.com/Znoj-Desktop/MHD\">MHD - n\u00e1vrh IS a SQL skripty</a></p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/MHD_IS.git",
        "id": 7760309,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:19.546Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "MHD - implementace \u010d\u00e1sti navr\u017een\u00e9ho IS",
        "name_with_namespace": "Znoj-Desktop / MHD - implementace \u010d\u00e1sti navr\u017een\u00e9ho IS",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "MHD_IS",
        "path_with_namespace": "Znoj-Desktop/MHD_IS",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/MHD_IS/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489411s9sDmyW96GV7zqnsyEH",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-mhd-is-7760309-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/MHD_IS.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2013",
            "C#",
            "asp.net"
        ],
        "topics": [
            "2013",
            "C#",
            "asp.net"
        ],
        "updated_at": "2024-03-30T13:01:19.546Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/MHD_IS",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7760284/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7760284/events",
            "issues": "https://gitlab.com/api/v4/projects/7760284/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760284/labels",
            "members": "https://gitlab.com/api/v4/projects/7760284/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760284/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760284/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760284"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760284/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/mhd",
        "created_at": "2018-08-04T19:06:39.070Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, SQL - N\u00e1vrh IS - Specifikace zad\u00e1n\u00ed, Datov\u00e1 anal\u00fdza, funk\u010dn\u00ed z\u00e1vislosti a norm\u00e1ln\u00ed formy + SQL skripty",
        "description_html": "<p data-sourcepos=\"1:1-1:118\" dir=\"auto\">2012, SQL - N\u00e1vrh IS - Specifikace zad\u00e1n\u00ed, Datov\u00e1 anal\u00fdza, funk\u010dn\u00ed z\u00e1vislosti a norm\u00e1ln\u00ed formy + SQL skripty</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/MHD.git",
        "id": 7760284,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:02:25.698Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "MHD - n\u00e1vrh IS a SQL skripty",
        "name_with_namespace": "Znoj-Desktop / MHD - n\u00e1vrh IS a SQL skripty",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "MHD",
        "path_with_namespace": "Znoj-Desktop/MHD",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/MHD/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941zEiQo_xLrUuYpL_RmXY2",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-mhd-7760284-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/MHD.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2012",
            "sql"
        ],
        "topics": [
            "2012",
            "sql"
        ],
        "updated_at": "2024-03-30T13:02:25.698Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/MHD",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7760202/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7760202/events",
            "issues": "https://gitlab.com/api/v4/projects/7760202/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760202/labels",
            "members": "https://gitlab.com/api/v4/projects/7760202/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760202/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760202/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760202"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760202/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/odmocnina",
        "created_at": "2018-08-04T18:56:07.543Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2011, C++ - Program provede v\u00fdpo\u010det druh\u00e9 odmocniny. \u010c\u00edslo, z kter\u00e9ho se bude odmocnina vypo\u010d\u00edt\u00e1vat m\u016f\u017ee obsahovat velk\u00e9 mno\u017estv\u00ed cifer (i nad 10 tis\u00edc).",
        "description_html": "<p data-sourcepos=\"1:1-1:168\" dir=\"auto\">2011, C++ - Program provede v\u00fdpo\u010det druh\u00e9 odmocniny. \u010c\u00edslo, z kter\u00e9ho se bude odmocnina vypo\u010d\u00edt\u00e1vat m\u016f\u017ee obsahovat velk\u00e9 mno\u017estv\u00ed cifer (i nad 10 tis\u00edc).</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/odmocnina.git",
        "id": 7760202,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:46.774Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Odmocnina",
        "name_with_namespace": "Znoj-Desktop / Odmocnina",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "odmocnina",
        "path_with_namespace": "Znoj-Desktop/odmocnina",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/odmocnina/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941cX1mzkb4QESyeKR5Tqdc",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-odmocnina-7760202-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/odmocnina.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2011",
            "C++"
        ],
        "topics": [
            "2011",
            "C++"
        ],
        "updated_at": "2024-03-30T13:01:46.774Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/odmocnina",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7760174/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7760174/events",
            "issues": "https://gitlab.com/api/v4/projects/7760174/issues",
            "labels": "https://gitlab.com/api/v4/projects/7760174/labels",
            "members": "https://gitlab.com/api/v4/projects/7760174/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7760174/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7760174/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7760174"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7760174/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/konkordance",
        "created_at": "2018-08-04T18:50:51.469Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, C++ - Konkordance - listing all words existing in given text with numbers representing line(s) where particular word exists",
        "description_html": "<p data-sourcepos=\"1:1-1:129\" dir=\"auto\">2012, C++ - Konkordance - listing all words existing in given text with numbers representing line(s) where particular word exists</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/konkordance.git",
        "id": 7760174,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:39.200Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Konkordance",
        "name_with_namespace": "Znoj-Desktop / Konkordance",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "konkordance",
        "path_with_namespace": "Znoj-Desktop/konkordance",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/konkordance/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941ZwYtBFoYpNtZgHaqEdFD",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-konkordance-7760174-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/konkordance.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2012",
            "C++"
        ],
        "topics": [
            "2012",
            "C++"
        ],
        "updated_at": "2024-03-30T13:01:39.200Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/konkordance",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7759369/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7759369/events",
            "issues": "https://gitlab.com/api/v4/projects/7759369/issues",
            "labels": "https://gitlab.com/api/v4/projects/7759369/labels",
            "members": "https://gitlab.com/api/v4/projects/7759369/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7759369/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7759369/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7759369"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7759369/R-tree_S.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/n-grams",
        "created_at": "2018-08-04T16:58:37.142Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2014, C++ - Pr\u00e1ce se zab\u00fdv\u00e1 stromov\u00fdmi datov\u00fdmi strukturami. P\u0159edev\u0161\u00edm B+ stromy a R\u2013stromy,",
        "description_html": "<p data-sourcepos=\"1:1-1:102\" dir=\"auto\">2014, C++ - Pr\u00e1ce se zab\u00fdv\u00e1 stromov\u00fdmi datov\u00fdmi strukturami. P\u0159edev\u0161\u00edm B+ stromy a R\u2013stromy,</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/n-grams.git",
        "id": 7759369,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:16.909Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Bakal\u00e1\u0159sk\u00e1 pr\u00e1ce N-grams",
        "name_with_namespace": "Znoj-Desktop / Bakal\u00e1\u0159sk\u00e1 pr\u00e1ce N-grams",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "n-grams",
        "path_with_namespace": "Znoj-Desktop/n-grams",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/n-grams/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941zZ-xFK8djPEe3Ny7xkX-",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-n-grams-7759369-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/n-grams.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2014",
            "C++"
        ],
        "topics": [
            "2014",
            "C++"
        ],
        "updated_at": "2024-03-30T13:04:16.909Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/n-grams",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7759254/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7759254/events",
            "issues": "https://gitlab.com/api/v4/projects/7759254/issues",
            "labels": "https://gitlab.com/api/v4/projects/7759254/labels",
            "members": "https://gitlab.com/api/v4/projects/7759254/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7759254/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7759254/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7759254"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7759254/tacr.jpeg",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/tacr",
        "created_at": "2018-08-04T16:42:32.656Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, C#, ASP.NET - Implementign WebAPI and Notification for TACR (Technology Agency of the Czech\r\nRepublic) project during 816 working hours.",
        "description_html": "<p data-sourcepos=\"1:1-2:43\" dir=\"auto\">2017, C#, ASP.NET - Implementign WebAPI and Notification for TACR (Technology Agency of the Czech\nRepublic) project during 816 working hours.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/tacr.git",
        "id": 7759254,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:31.542Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "TACR",
        "name_with_namespace": "Znoj-Desktop / TACR",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "tacr",
        "path_with_namespace": "Znoj-Desktop/tacr",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/tacr/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941ge-gwHsGoozBNeSe8WtS",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-tacr-7759254-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/tacr.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "C#",
            "asp.net"
        ],
        "topics": [
            "2017",
            "C#",
            "asp.net"
        ],
        "updated_at": "2024-03-30T13:04:31.542Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/tacr",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7759168/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7759168/events",
            "issues": "https://gitlab.com/api/v4/projects/7759168/issues",
            "labels": "https://gitlab.com/api/v4/projects/7759168/labels",
            "members": "https://gitlab.com/api/v4/projects/7759168/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7759168/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7759168/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7759168"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7759168/goodwareDownloadScript.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/downloadscript",
        "created_at": "2018-08-04T16:30:45.047Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, C# - Script for getting non-malware software from publicly accessible sources like Studna, SourceForge or FileHippo",
        "description_html": "<p data-sourcepos=\"1:1-1:121\" dir=\"auto\">2017, C# - Script for getting non-malware software from publicly accessible sources like Studna, SourceForge or FileHippo</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/downloadScript.git",
        "id": 7759168,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:54.851Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Download script",
        "name_with_namespace": "Znoj-Desktop / Download script",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "downloadScript",
        "path_with_namespace": "Znoj-Desktop/downloadScript",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/downloadScript/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941wPFnu2SrA6KXMmeEwSDM",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-downloadscript-7759168-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/downloadScript.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "C#"
        ],
        "topics": [
            "2017",
            "C#"
        ],
        "updated_at": "2024-03-30T13:01:54.851Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/downloadScript",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757328/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757328/events",
            "issues": "https://gitlab.com/api/v4/projects/7757328/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757328/labels",
            "members": "https://gitlab.com/api/v4/projects/7757328/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757328/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757328/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757328"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757328/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/morseovka",
        "created_at": "2018-08-04T11:49:37.096Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, C++, Java - P\u0159eklada\u010d textu z a do morseovky",
        "description_html": "<p data-sourcepos=\"1:1-1:52\" dir=\"auto\">2012, C++, Java - P\u0159eklada\u010d textu z a do morseovky</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/morseovka.git",
        "id": 7757328,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:03:53.652Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Morseovka",
        "name_with_namespace": "Znoj-Desktop / Morseovka",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "morseovka",
        "path_with_namespace": "Znoj-Desktop/morseovka",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/morseovka/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489418U_uN9HNCtisPYGvqNKT",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-morseovka-7757328-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/morseovka.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2012",
            "C++",
            "java"
        ],
        "topics": [
            "2012",
            "C++",
            "java"
        ],
        "updated_at": "2024-03-30T13:03:53.652Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/morseovka",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757221/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757221/events",
            "issues": "https://gitlab.com/api/v4/projects/7757221/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757221/labels",
            "members": "https://gitlab.com/api/v4/projects/7757221/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757221/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757221/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757221"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757221/onlinejudgelogo.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/spr",
        "created_at": "2018-08-04T11:31:20.042Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, C++ - 14 programs from school subject \"Programming seminar\" \r\nTasks are from https://uva.onlinejudge.org",
        "description_html": "<p data-sourcepos=\"1:1-2:42\" dir=\"auto\">2013, C++ - 14 programs from school subject \"Programming seminar\"\nTasks are from <a href=\"https://uva.onlinejudge.org\" rel=\"nofollow noreferrer noopener\" target=\"_blank\">https://uva.onlinejudge.org</a></p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/spr.git",
        "id": 7757221,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-20T23:40:10.149Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "SPR - Programming seminar - uva.onlinejudge.org",
        "name_with_namespace": "Znoj-Desktop / SPR - Programming seminar - uva.onlinejudge.org",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "spr",
        "path_with_namespace": "Znoj-Desktop/spr",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/spr/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941E5XDwySzwd3uQh_znuAj",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-spr-7757221-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/spr.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2013",
            "C++"
        ],
        "topics": [
            "2013",
            "C++"
        ],
        "updated_at": "2024-01-19T04:55:54.777Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/spr",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757209/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757209/events",
            "issues": "https://gitlab.com/api/v4/projects/7757209/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757209/labels",
            "members": "https://gitlab.com/api/v4/projects/7757209/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757209/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757209/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757209"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757209/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/smscutter",
        "created_at": "2018-08-04T11:29:17.024Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2012, C++ - SMS cutter",
        "description_html": "<p data-sourcepos=\"1:1-1:22\" dir=\"auto\">2012, C++ - SMS cutter</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/smsCutter.git",
        "id": 7757209,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:27.166Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "smsCutter",
        "name_with_namespace": "Znoj-Desktop / smsCutter",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "smsCutter",
        "path_with_namespace": "Znoj-Desktop/smsCutter",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/smsCutter/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941B5v4ompT5n1LjzdXyhoy",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-smscutter-7757209-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/smsCutter.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2012",
            "C++"
        ],
        "topics": [
            "2012",
            "C++"
        ],
        "updated_at": "2024-03-30T13:01:27.166Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/smsCutter",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757156/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757156/events",
            "issues": "https://gitlab.com/api/v4/projects/7757156/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757156/labels",
            "members": "https://gitlab.com/api/v4/projects/7757156/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757156/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757156/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757156"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757156/Qt_logo_2016.svg.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/uro",
        "created_at": "2018-08-04T11:22:32.530Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, C++ - GUI in QT - Cars and Employees, Options, Vehicles Database ",
        "description_html": "<p data-sourcepos=\"1:1-1:71\" dir=\"auto\">2013, C++ - GUI in QT - Cars and Employees, Options, Vehicles Database</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/uro.git",
        "id": 7757156,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:35.797Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "QT GUI projects",
        "name_with_namespace": "Znoj-Desktop / QT GUI projects",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "uro",
        "path_with_namespace": "Znoj-Desktop/uro",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/uro/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941RM49Yfo5zpc5VF3dE6u4",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-uro-7757156-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/uro.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2013",
            "C++"
        ],
        "topics": [
            "2013",
            "C++"
        ],
        "updated_at": "2024-03-30T13:04:35.797Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/uro",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757119/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757119/events",
            "issues": "https://gitlab.com/api/v4/projects/7757119/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757119/labels",
            "members": "https://gitlab.com/api/v4/projects/7757119/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757119/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757119/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757119"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757119/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/hash",
        "created_at": "2018-08-04T11:17:40.310Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, C# - Brute force md5 hash cracker (paralel and non paralel algorithm)",
        "description_html": "<p data-sourcepos=\"1:1-1:75\" dir=\"auto\">2015, C# - Brute force md5 hash cracker (paralel and non paralel algorithm)</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/hash.git",
        "id": 7757119,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:31.556Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Brute force md5 hash cracker",
        "name_with_namespace": "Znoj-Desktop / Brute force md5 hash cracker",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "hash",
        "path_with_namespace": "Znoj-Desktop/hash",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/hash/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941eQxZypW_JAaf9Gwr7xae",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-hash-7757119-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/hash.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "C#"
        ],
        "topics": [
            "2015",
            "C#"
        ],
        "updated_at": "2024-03-30T13:01:31.556Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/hash",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757100/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757100/events",
            "issues": "https://gitlab.com/api/v4/projects/7757100/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757100/labels",
            "members": "https://gitlab.com/api/v4/projects/7757100/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757100/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757100/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757100"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757100/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/dosattack",
        "created_at": "2018-08-04T11:15:48.603Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, C# - Simple DOS attack",
        "description_html": "<p data-sourcepos=\"1:1-1:28\" dir=\"auto\">2015, C# - Simple DOS attack</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/dosAttack.git",
        "id": 7757100,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:04:05.399Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Simple DOS attack",
        "name_with_namespace": "Znoj-Desktop / Simple DOS attack",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "dosAttack",
        "path_with_namespace": "Znoj-Desktop/dosAttack",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/dosAttack/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": false,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941RoVSdQ9J1W2oKMDYZvHZ",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-dosattack-7757100-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/dosAttack.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "C#"
        ],
        "topics": [
            "2015",
            "C#"
        ],
        "updated_at": "2024-03-30T13:04:05.399Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/dosAttack",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757042/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757042/events",
            "issues": "https://gitlab.com/api/v4/projects/7757042/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757042/labels",
            "members": "https://gitlab.com/api/v4/projects/7757042/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757042/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757042/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757042"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757042/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/kod",
        "created_at": "2018-08-04T11:05:28.057Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, C++ - My text compression algorithm",
        "description_html": "<p data-sourcepos=\"1:1-1:41\" dir=\"auto\">2015, C++ - My text compression algorithm</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/KOD.git",
        "id": 7757042,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:42.340Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "KOD",
        "name_with_namespace": "Znoj-Desktop / KOD",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "KOD",
        "path_with_namespace": "Znoj-Desktop/KOD",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/KOD/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941dSaTsqNpvNs1aNmQfm6-",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-kod-7757042-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/KOD.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "C++"
        ],
        "topics": [
            "2015",
            "C++"
        ],
        "updated_at": "2024-03-30T13:01:42.340Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/KOD",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757014/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757014/events",
            "issues": "https://gitlab.com/api/v4/projects/7757014/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757014/labels",
            "members": "https://gitlab.com/api/v4/projects/7757014/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757014/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757014/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757014"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757014/screen.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/aed_bakalarka",
        "created_at": "2018-08-04T10:59:59.188Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, C# - program for Bc. Jan Gronych's bachelor thesis to track AED's in Olomouc region",
        "description_html": "<p data-sourcepos=\"1:1-1:89\" dir=\"auto\">2015, C# - program for Bc. Jan Gronych's bachelor thesis to track AED's in Olomouc region</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/AED_bakalarka.git",
        "id": 7757014,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:02:11.598Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "AED_bakalarka",
        "name_with_namespace": "Znoj-Desktop / AED_bakalarka",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "AED_bakalarka",
        "path_with_namespace": "Znoj-Desktop/AED_bakalarka",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/AED_bakalarka/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941yvfnVuTpAQFR4bKJQJ3o",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-aed-bakalarka-7757014-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/AED_bakalarka.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "C#"
        ],
        "topics": [
            "2015",
            "C#"
        ],
        "updated_at": "2024-03-30T13:02:11.598Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/AED_bakalarka",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7737226/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7737226/events",
            "issues": "https://gitlab.com/api/v4/projects/7737226/issues",
            "labels": "https://gitlab.com/api/v4/projects/7737226/labels",
            "members": "https://gitlab.com/api/v4/projects/7737226/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7737226/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7737226/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7737226"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7737226/library.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-desktop/antivirusheader",
        "created_at": "2018-08-02T19:11:51.767Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2017, C++- library for reading libraries from PE files based on \"PE Maker\" from Ashkbiz Danehkar.  \r\nFrom me:\r\n- 64-bit files support\r\n- upgraded to use MSVC2017 64b\r\n- updated API\r\n- bug fixes\r\n- stability fixes, exceptions handling\r\n- fixed memory leaks",
        "description_html": "<p data-sourcepos=\"1:1-2:8\" dir=\"auto\">2017, C++- library for reading libraries from PE files based on \"PE Maker\" from Ashkbiz Danehkar. \nFrom me:</p>\n \n64-bit files support\nupgraded to use MSVC2017 64b\nupdated API\nbug fixes\nstability fixes, exceptions handling\nfixed memory leaks\n ",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Desktop/antivirusHeader.git",
        "id": 7737226,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2024-03-30T13:01:59.242Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "Extract info from PE headers",
        "name_with_namespace": "Znoj-Desktop / Extract info from PE headers",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335002/20120118_183141.jpg",
            "full_path": "Znoj-Desktop",
            "id": 3335002,
            "kind": "group",
            "name": "Znoj-Desktop",
            "parent_id": null,
            "path": "Znoj-Desktop",
            "web_url": "https://gitlab.com/groups/Znoj-Desktop"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "antivirusHeader",
        "path_with_namespace": "Znoj-Desktop/antivirusHeader",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Desktop/antivirusHeader/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941GcyeEVrsfywSTLycZi4a",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-desktop-antivirusheader-7737226-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Desktop/antivirusHeader.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2017",
            "C++"
        ],
        "topics": [
            "2017",
            "C++"
        ],
        "updated_at": "2024-03-30T13:01:59.242Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Desktop/antivirusHeader",
        "wiki_access_level": "private",
        "wiki_enabled": true
    }
]