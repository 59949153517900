import { Popover, Table, Tag, Typography } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./ProjectsTable.css";

class ProjectsTable extends Component {
  state = {
    data: [],
    pagination: {},
    filteredGroupInfo: null,
    filteredTagInfo: null,
    sortField: "description",
    sortOrder: "descend",
    loading: false,
  };

  getTags = (tags, from, to) =>
    tags.slice(from, to).map((tag) => (
      <Tag
        color="blue"
        key={tag}
        onClick={(e) => {
          e.cancelBubble = true;
          if (e.stopPropagation) e.stopPropagation();
          this.setState({
            data: [...this.props.data].filter((project) =>
              this.filter(project.tag_list, [tag])
            ),
            filteredTagInfo: [tag],
          });
          this.props.setParams({
            ...this.props.params,
            tag_list: [tag],
          });
        }}
      >
        {tag}
      </Tag>
    ));
  columns = () => [
    {
      title: "",
      dataIndex: "icon",
      sorter: false,
      className: "col-icon",
      render: (icon) => (
        <img
          alt="img"
          src={icon}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `${window.location.origin}/icon.png`;
          }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      sortOrder: this.state.sortField === "name" ? this.state.sortOrder : null,
      sorter: true,
      className: "col-name",
    },
    this.props.isAll
      ? {
          title: "Group",
          dataIndex: ["namespace", "name"],
          filteredValue: this.state.filteredGroupInfo || null,
          filters: this.props.data
            .map((project) => project.namespace.name)
            .filter((obect, index, arr) => arr.indexOf(obect) === index)
            .sort()
            .map((name) => ({
              text: name,
              value: name,
            })),
          filtered: true,
          sortOrder:
            Array.isArray(this.state.sortField) &&
            this.state.sortField[0] === "namespace" &&
            this.state.sortField[1] === "name"
              ? this.state.sortOrder
              : null,
          sorter: true,
          className: "col-group",
        }
      : {},
    {
      title: "Tags",
      dataIndex: "tag_list",
      filteredValue: this.state.filteredTagInfo || null,
      filters: this.props.tags.map((tag) => ({
        text: tag === "public" ? "public source" : tag,
        value: tag,
      })),
      className: "col-tags",
      render: (tags) => (
        <span className="tags-span">
          {tags
            ? [
                this.getTags(tags, 0, 2),
                tags.length > 2 ? (
                  <Popover
                    key={"popover"}
                    content={this.getTags(tags, 2, tags.length)}
                    trigger="hover"
                  >
                    <Tag
                      color="#e8e8e8"
                      key={"more"}
                      onClick={(e) => {
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                      }}
                    >
                      <Typography.Text>...</Typography.Text>
                    </Tag>
                  </Popover>
                ) : null,
              ]
            : null}
        </span>
      ),
    },
    {
      title: "Desc",
      dataIndex: "description",
      sortOrder:
        this.state.sortField === "description" ? this.state.sortOrder : null,
      sorter: true,
      className: "col-desc",
      render: (desc) => <div className="desc-div">{desc}</div>,
    },
  ];

  componentDidMount() {
    // this.fetch();
    let stateParams = {
      pagination: { pageSize: 20 },
    };

    if (Object.keys(this.props.params).length === 0) {
      const newData = [...this.props.data];
      this.sort(newData);
      this.setState({
        ...stateParams,
        data: newData,
      });
    } else {
      this.setState({
        ...stateParams,
      });
      this.fetch(this.props.params);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  fetch = (params = {}) => {
    this.props.setParams(params);
    let newData = [...this.props.data];
    const order = params.sortOrder === "descend" ? -1 : 1;
    this.sort(newData, params.sortField, order);

    if (params.tag_list && params.tag_list.length > 0) {
      newData = newData.filter((project) =>
        this.filter(project.tag_list, params.tag_list, project.visibility)
      );
    }
    if (params["namespace.name"] && params["namespace.name"].length > 0) {
      newData = newData.filter((project) => {
        for (let i = 0; i < params["namespace.name"].length; i++) {
          if (
            project.path_with_namespace.split("/")[0] ===
            params["namespace.name"][i]
          ) {
            return true;
          }
        }
        return false;
      });
    }

    this.setState({
      loading: false,
      data: newData,
      sortField: params.sortField,
      sortOrder: params.sortOrder,
      filteredGroupInfo: params["namespace.name"],
      filteredTagInfo: params.tag_list,
    });
  };

  sort(data, sortField = "description", order = -1) {
    let newA;
    let newB;
    if (Array.isArray(sortField)) {
      sortField = "name_with_namespace";
    }
    data.sort((a, b) => {
      newA = a[sortField].toLowerCase();
      newB = b[sortField].toLowerCase();
      if (newA > newB) {
        return order;
      }
      if (newA < newB) {
        return -1 * order;
      }
      return 0;
    });
  }

  filter(projectList, tagList, visibility) {
    for (let i = 0; i < projectList.length; i++) {
      for (let j = 0; j < tagList.length; j++) {
        if (projectList[i] === tagList[j] || tagList[j] === visibility) {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    return (
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              return this.props.history.push("/" + record.path_with_namespace);
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
          };
        }}
        columns={this.columns()}
        rowKey={(record) => record.id}
        dataSource={this.state.data}
        pagination={this.state.pagination}
        loading={this.state.loading}
        onChange={this.handleTableChange}
        size="small"
      />
    );
  }
}

export default withRouter(ProjectsTable);
