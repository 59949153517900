import { Card, Col, Layout, Row } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppMenu from "./AppMenu";
import "./Home.css";
import MyFooter from "./MyFooter";

const { Content } = Layout;

class Home extends Component {
  render() {
    return (
      <Layout className="main-layout">
        <AppMenu />
        <Layout className="layout">
          <Content>
            <div className="content-div">
              <h1 className="title">My GitLab projects</h1>
              <Row justify="space-around" align="middle" key="card">
                <Col>
                  <Card hoverable className="main-card">
                    <Link
                      to={{
                        pathname: "/all",
                        params: {
                          "namespace.name": null,
                          page: 1,
                          results: 20,
                          sortField: "description",
                          sortOrder: "descend",
                          tag_list: null,
                        },
                      }}
                    >
                      <img
                        className="logo"
                        alt="icon"
                        src={`${window.location.origin}/logo.png`}
                      />
                    </Link>
                  </Card>
                </Col>
              </Row>
            </div>
          </Content>
          <MyFooter />
        </Layout>
      </Layout>
    );
  }
}

export default Home;
