/* eslint-disable import/no-anonymous-default-export */
export default [
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/8874774/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/8874774/events",
            "issues": "https://gitlab.com/api/v4/projects/8874774/issues",
            "labels": "https://gitlab.com/api/v4/projects/8874774/labels",
            "members": "https://gitlab.com/api/v4/projects/8874774/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/8874774/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/8874774/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/8874774"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/8874774/icon.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/pjproject-2.8",
        "created_at": "2018-10-15T15:37:21.717Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2018, Android - Build, configuration, improvement PJSIP library in android for custom implementation of VoIP communication",
        "description_html": "<p data-sourcepos=\"1:1-1:122\" dir=\"auto\">2018, Android - Build, configuration, improvement PJSIP library in android for custom implementation of VoIP communication</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/pjproject-2.8.git",
        "id": 8874774,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-28T17:36:11.424Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "PJSIP for custom VoIP",
        "name_with_namespace": "Znoj-Mobile / PJSIP for custom VoIP",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": true,
        "pages_access_level": "private",
        "path": "pjproject-2.8",
        "path_with_namespace": "Znoj-Mobile/pjproject-2.8",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/pjproject-2.8/-/blob/master/README.md",
        "releases_access_level": "private",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "private",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Rmqf1fsSAM5GawkMm8nR",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-pjproject-2-8-8874774-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/pjproject-2.8.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2018",
            "android"
        ],
        "topics": [
            "2018",
            "android"
        ],
        "updated_at": "2024-01-11T19:20:44.652Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/pjproject-2.8",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7761440/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7761440/events",
            "issues": "https://gitlab.com/api/v4/projects/7761440/issues",
            "labels": "https://gitlab.com/api/v4/projects/7761440/labels",
            "members": "https://gitlab.com/api/v4/projects/7761440/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7761440/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7761440/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7761440"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7761440/hero.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/sokoban",
        "created_at": "2018-08-04T22:17:30.363Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2013, Android - Android game sokoban created as a school project",
        "description_html": "<p data-sourcepos=\"1:1-1:64\" dir=\"auto\">2013, Android - Android game sokoban created as a school project</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/sokoban.git",
        "id": 7761440,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-28T17:36:28.786Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "sokoban",
        "name_with_namespace": "Znoj-Mobile / sokoban",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "sokoban",
        "path_with_namespace": "Znoj-Mobile/sokoban",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/sokoban/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941YH_wT4c9Vx8dEENVqiV8",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-sokoban-7761440-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/sokoban.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2013",
            "android"
        ],
        "topics": [
            "2013",
            "android"
        ],
        "updated_at": "2024-01-12T04:49:10.128Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/sokoban",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757260/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757260/events",
            "issues": "https://gitlab.com/api/v4/projects/7757260/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757260/labels",
            "members": "https://gitlab.com/api/v4/projects/7757260/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757260/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757260/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757260"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757260/ic_launcher.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/vkhapp",
        "created_at": "2018-08-04T11:37:09.529Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - Concept of an app for VKH Ostrava displaying the same content as the web page.",
        "description_html": "<p data-sourcepos=\"1:1-1:94\" dir=\"auto\">2015, Android - Concept of an app for VKH Ostrava displaying the same content as the web page.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/vkhApp.git",
        "id": 7757260,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-28T17:36:34.262Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "vkhApp",
        "name_with_namespace": "Znoj-Mobile / vkhApp",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "vkhApp",
        "path_with_namespace": "Znoj-Mobile/vkhApp",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/vkhApp/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941K7x_LakeDoDn_a8zFoLz",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-vkhapp-7757260-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/vkhApp.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "android"
        ],
        "topics": [
            "2015",
            "android"
        ],
        "updated_at": "2024-01-19T04:56:05.044Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/vkhApp",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757174/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757174/events",
            "issues": "https://gitlab.com/api/v4/projects/7757174/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757174/labels",
            "members": "https://gitlab.com/api/v4/projects/7757174/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757174/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757174/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757174"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757174/ic_launcher.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/sestmesicu",
        "created_at": "2018-08-04T11:25:23.629Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - Android game created for my girlfriend. It was part of a puzzle and after each level there was clue - key to specific part of the puzzle.",
        "description_html": "<p data-sourcepos=\"1:1-1:153\" dir=\"auto\">2015, Android - Android game created for my girlfriend. It was part of a puzzle and after each level there was clue - key to specific part of the puzzle.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/sestMesicu.git",
        "id": 7757174,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-28T17:36:23.284Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "sestMesicu",
        "name_with_namespace": "Znoj-Mobile / sestMesicu",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "sestMesicu",
        "path_with_namespace": "Znoj-Mobile/sestMesicu",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/sestMesicu/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941HfpeTvVBmeVMsZ9zZX7E",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-sestmesicu-7757174-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/sestMesicu.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "android"
        ],
        "topics": [
            "2015",
            "android"
        ],
        "updated_at": "2024-01-19T05:15:27.267Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/sestMesicu",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7757064/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7757064/events",
            "issues": "https://gitlab.com/api/v4/projects/7757064/issues",
            "labels": "https://gitlab.com/api/v4/projects/7757064/labels",
            "members": "https://gitlab.com/api/v4/projects/7757064/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7757064/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7757064/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7757064"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7757064/pt.jpg",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/partitions",
        "created_at": "2018-08-04T11:11:05.798Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - App listing partitions with used and free space in android devices",
        "description_html": "<p data-sourcepos=\"1:1-1:82\" dir=\"auto\">2015, Android - App listing partitions with used and free space in android devices</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/partitions.git",
        "id": 7757064,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-29T20:35:09.617Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "partitions",
        "name_with_namespace": "Znoj-Mobile / partitions",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "partitions",
        "path_with_namespace": "Znoj-Mobile/partitions",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/partitions/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941PqZnXRE15bRq1ChYQwK5",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-partitions-7757064-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/partitions.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "android"
        ],
        "topics": [
            "2015",
            "android"
        ],
        "updated_at": "2024-01-19T05:15:17.208Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/partitions",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7756952/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7756952/events",
            "issues": "https://gitlab.com/api/v4/projects/7756952/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756952/labels",
            "members": "https://gitlab.com/api/v4/projects/7756952/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756952/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756952/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756952"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756952/DjBox.jpg",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/cdd",
        "created_at": "2018-08-04T10:50:37.402Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - Concept of android app for fictive company DJ in the Box.\r\nMore about whole project could be found in folder *docs*.",
        "description_html": "<p data-sourcepos=\"1:1-2:57\" dir=\"auto\">2015, Android - Concept of android app for fictive company DJ in the Box.\nMore about whole project could be found in folder <em data-sourcepos=\"2:51-2:56\">docs</em>.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/CDD.git",
        "id": 7756952,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-28T17:36:01.553Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "DJ in the Box - CDD",
        "name_with_namespace": "Znoj-Mobile / DJ in the Box - CDD",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "CDD",
        "path_with_namespace": "Znoj-Mobile/CDD",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/CDD/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489413YkdyeMogzckHXy_aNsk",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-cdd-7756952-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/CDD.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "android"
        ],
        "topics": [
            "2015",
            "android"
        ],
        "updated_at": "2024-01-19T05:15:16.726Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/CDD",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7756938/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7756938/events",
            "issues": "https://gitlab.com/api/v4/projects/7756938/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756938/labels",
            "members": "https://gitlab.com/api/v4/projects/7756938/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756938/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756938/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756938"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756938/ic_launcher.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/cas",
        "created_at": "2018-08-04T10:49:10.580Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - App for counting difference between 2 dates - one is current, one is in the future.  \r\nOutput is in various time units.",
        "description_html": "<p data-sourcepos=\"1:1-2:32\" dir=\"auto\">2015, Android - App for counting difference between 2 dates - one is current, one is in the future. \nOutput is in various time units.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/cas.git",
        "id": 7756938,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-28T17:35:55.001Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "cas",
        "name_with_namespace": "Znoj-Mobile / cas",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "cas",
        "path_with_namespace": "Znoj-Mobile/cas",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/cas/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941tissXp-cbL2FspyLAipD",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-cas-7756938-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/cas.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "android"
        ],
        "topics": [
            "2015",
            "android"
        ],
        "updated_at": "2024-01-12T04:45:57.350Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/cas",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7756899/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7756899/events",
            "issues": "https://gitlab.com/api/v4/projects/7756899/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756899/labels",
            "members": "https://gitlab.com/api/v4/projects/7756899/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756899/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756899/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756899"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756899/bf.jpg",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/bruteforce",
        "created_at": "2018-08-04T10:44:30.443Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android NDK - Android app implemented to break md5 hashes using brute force attack implemented in C++",
        "description_html": "<p data-sourcepos=\"1:1-1:107\" dir=\"auto\">2015, Android NDK - Android app implemented to break md5 hashes using brute force attack implemented in C++</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/bruteForce.git",
        "id": 7756899,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-28T17:35:49.412Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "bruteForce",
        "name_with_namespace": "Znoj-Mobile / bruteForce",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "bruteForce",
        "path_with_namespace": "Znoj-Mobile/bruteForce",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/bruteForce/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941J4pipQjAYWypxgQmseWT",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-bruteforce-7756899-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/bruteForce.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "Android NDK"
        ],
        "topics": [
            "2015",
            "Android NDK"
        ],
        "updated_at": "2024-01-12T04:45:47.457Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/bruteForce",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7756887/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7756887/events",
            "issues": "https://gitlab.com/api/v4/projects/7756887/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756887/labels",
            "members": "https://gitlab.com/api/v4/projects/7756887/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756887/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756887/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756887"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756887/ic_launcher.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "private",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/batterystats",
        "created_at": "2018-08-04T10:42:42.084Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2015, Android - Android app running on background, periodically checks battery stats and send it to local server. Interval is configurable.",
        "description_html": "<p data-sourcepos=\"1:1-1:139\" dir=\"auto\">2015, Android - Android app running on background, periodically checks battery stats and send it to local server. Interval is configurable.</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "private",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/batteryStats.git",
        "id": 7756887,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "private",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-28T17:35:43.196Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "private",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "batteryStats",
        "name_with_namespace": "Znoj-Mobile / batteryStats",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": false,
        "pages_access_level": "public",
        "path": "batteryStats",
        "path_with_namespace": "Znoj-Mobile/batteryStats",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/batteryStats/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": true,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR1348941Df1jyRZhKir3AZkDBaVx",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-batterystats-7756887-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "private",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/batteryStats.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2015",
            "android",
            "profiq"
        ],
        "topics": [
            "2015",
            "android",
            "profiq"
        ],
        "updated_at": "2024-01-19T05:06:11.569Z",
        "visibility": "public",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/batteryStats",
        "wiki_access_level": "private",
        "wiki_enabled": true
    },
    {
        "_links": {
            "cluster_agents": "https://gitlab.com/api/v4/projects/7756673/cluster_agents",
            "events": "https://gitlab.com/api/v4/projects/7756673/events",
            "issues": "https://gitlab.com/api/v4/projects/7756673/issues",
            "labels": "https://gitlab.com/api/v4/projects/7756673/labels",
            "members": "https://gitlab.com/api/v4/projects/7756673/members",
            "merge_requests": "https://gitlab.com/api/v4/projects/7756673/merge_requests",
            "repo_branches": "https://gitlab.com/api/v4/projects/7756673/repository/branches",
            "self": "https://gitlab.com/api/v4/projects/7756673"
        },
        "allow_merge_on_skipped_pipeline": null,
        "analytics_access_level": "enabled",
        "archived": false,
        "auto_cancel_pending_pipelines": "enabled",
        "auto_devops_deploy_strategy": "continuous",
        "auto_devops_enabled": false,
        "autoclose_referenced_issues": true,
        "avatar_url": "https://gitlab.com/uploads/-/system/project/avatar/7756673/profiqLogo.png",
        "build_git_strategy": "fetch",
        "build_timeout": 3600,
        "builds_access_level": "enabled",
        "can_create_merge_request_in": true,
        "ci_allow_fork_pipelines_to_run_in_parent_project": true,
        "ci_config_path": null,
        "ci_default_git_depth": null,
        "ci_forward_deployment_enabled": null,
        "ci_forward_deployment_rollback_allowed": true,
        "ci_job_token_scope_enabled": false,
        "ci_separated_caches": true,
        "compliance_frameworks": [],
        "container_registry_access_level": "enabled",
        "container_registry_enabled": true,
        "container_registry_image_prefix": "registry.gitlab.com/znoj-mobile/helloworlddynepic",
        "created_at": "2018-08-04T10:11:51.914Z",
        "creator_id": 2588219,
        "default_branch": "master",
        "description": "2018, Android - demonstration using alternative SDK (openid - oauth2) for Dynepic playPORTAL. It was before they released theirs SDK for communication",
        "description_html": "<p data-sourcepos=\"1:1-1:150\" dir=\"auto\">2018, Android - demonstration using alternative SDK (openid - oauth2) for Dynepic playPORTAL. It was before they released theirs SDK for communication</p>",
        "emails_disabled": false,
        "emails_enabled": true,
        "empty_repo": false,
        "enforce_auth_checks_on_uploads": true,
        "environments_access_level": "enabled",
        "external_authorization_classification_label": "",
        "feature_flags_access_level": "enabled",
        "forking_access_level": "enabled",
        "forks_count": 0,
        "group_runners_enabled": true,
        "http_url_to_repo": "https://gitlab.com/Znoj-Mobile/HelloWorldDynepic.git",
        "id": 7756673,
        "import_status": "none",
        "import_type": null,
        "import_url": null,
        "infrastructure_access_level": "enabled",
        "issue_branch_template": null,
        "issues_access_level": "enabled",
        "issues_enabled": true,
        "jobs_enabled": true,
        "keep_latest_artifact": true,
        "last_activity_at": "2019-01-29T20:33:00.806Z",
        "lfs_enabled": true,
        "merge_commit_template": null,
        "merge_method": "merge",
        "merge_requests_access_level": "enabled",
        "merge_requests_enabled": true,
        "model_experiments_access_level": "enabled",
        "model_registry_access_level": "enabled",
        "monitor_access_level": "enabled",
        "name": "HelloWorldDynepic",
        "name_with_namespace": "Znoj-Mobile / HelloWorldDynepic",
        "namespace": {
            "avatar_url": "/uploads/-/system/group/avatar/3335000/2011-04-24_22.55.012.png",
            "full_path": "Znoj-Mobile",
            "id": 3335000,
            "kind": "group",
            "name": "Znoj-Mobile",
            "parent_id": null,
            "path": "Znoj-Mobile",
            "web_url": "https://gitlab.com/groups/Znoj-Mobile"
        },
        "only_allow_merge_if_all_discussions_are_resolved": false,
        "only_allow_merge_if_pipeline_succeeds": false,
        "open_issues_count": 0,
        "packages_enabled": null,
        "pages_access_level": "public",
        "path": "HelloWorldDynepic",
        "path_with_namespace": "Znoj-Mobile/HelloWorldDynepic",
        "printing_merge_request_link_enabled": true,
        "public_jobs": true,
        "readme_url": "https://gitlab.com/Znoj-Mobile/HelloWorldDynepic/-/blob/master/README.md",
        "releases_access_level": "enabled",
        "remove_source_branch_after_merge": null,
        "repository_access_level": "enabled",
        "repository_object_format": "sha1",
        "request_access_enabled": false,
        "requirements_access_level": "enabled",
        "requirements_enabled": false,
        "resolve_outdated_diff_discussions": false,
        "restrict_user_defined_variables": false,
        "runner_token_expiration_interval": null,
        "runners_token": "GR13489412ZVfZJbcUQkmbuzYk1KJ",
        "security_and_compliance_access_level": "private",
        "security_and_compliance_enabled": true,
        "service_desk_address": "contact-project+znoj-mobile-helloworlddynepic-7756673-issue-@incoming.gitlab.com",
        "service_desk_enabled": true,
        "shared_runners_enabled": true,
        "shared_with_groups": [],
        "snippets_access_level": "enabled",
        "snippets_enabled": true,
        "squash_commit_template": null,
        "squash_option": "default_off",
        "ssh_url_to_repo": "git@gitlab.com:Znoj-Mobile/HelloWorldDynepic.git",
        "star_count": 0,
        "suggestion_commit_message": null,
        "tag_list": [
            "2018",
            "android",
            "profiq"
        ],
        "topics": [
            "2018",
            "android",
            "profiq"
        ],
        "updated_at": "2024-01-12T04:45:46.973Z",
        "visibility": "private",
        "warn_about_potentially_unwanted_characters": true,
        "web_url": "https://gitlab.com/Znoj-Mobile/HelloWorldDynepic",
        "wiki_access_level": "enabled",
        "wiki_enabled": true
    }
]