import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Group from "./Group/Group";
import Home from "./Home";
import { GitlabGroups } from "./configs/groups";

class MyRouter extends Component {
  render() {
    return (
      <Router>
        <React.Fragment>
          <Route exact path="/" component={Home} />
          <Route
            key="all"
            path={"/all"}
            render={() => <Group groupName={"All groups"} />}
          />
          {Object.keys(GitlabGroups).map(key => (
            <Route
              key={key}
              path={"/" + GitlabGroups[key].full_path}
              render={() => <Group groupName={GitlabGroups[key].full_name} />}
            />
          ))}
        </React.Fragment>
      </Router>
    );
  }
}

export default MyRouter;
