import { Card, Spin } from "antd";
import React, { Component } from "react";
import * as ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import "./ProjectPage.css";

class ProjectPage extends Component {
  state = {
    markdown: "",
    tags: [],
    group: "",
    project: "",
    isAll: false,
    loading: true,
    link: null,
  };

  componentDidMount() {
    let loading = true;
    try {
      const readmePath = require(`../../public/${this.props.group}/${this.props.project}/README.md`);
      fetch(readmePath.default)
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          const textWithFixedPaths = text.replace(
            /\.?\/?README\//g,
            `${window.location.origin}/${this.props.group}/${this.props.project}/README/`
          );
          this.setState({
            markdown: textWithFixedPaths,
            loading: false,
          });
        });
    } catch (e) {
      loading = false;
    }
    this.setState({
      markdown: "",
      loading: loading,
      tags: this.props.tags,
      group: this.props.group,
      project: this.props.project,
      isAll: this.props.isAll,
      link: this.props.link,
    });
  }

  imageResize = (props) => (
    <p className="project-image-p ">
      <img className="project-image" {...props} alt="img" />
    </p>
  );

  render() {
    const { loading, markdown } = this.state;

    if (loading) {
      return <Spin tip="Loading..." />;
    }

    return (
      <div className="project-top-div">
        <Card className="project-card" title={this.props.projectName}>
          {this.props.link && (
            <React.Fragment>
              <h3>
                <b>GitLab link</b>
              </h3>
              <p>
                <a href={this.props.link}>{this.props.link}</a>
              </p>
              <hr />
            </React.Fragment>
          )}
          <ReactMarkdown
            children={markdown}
            plugins={[gfm]}
            escapeHtml={false}
            renderers={{
              paragraph: (props) => <div {...props} />,
              image: this.imageResize,
            }}
          />
        </Card>
      </div>
    );
  }
}

export default ProjectPage;
